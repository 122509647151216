import "./VisualizePOI.css";
import React, {useCallback} from "react";
import {throttled} from "../../../utility/Utilities";

function halfWidthOfRectangle (childAspectRatio, parentAspectRatio = 16 / 9) {
    if (typeof childAspectRatio === "string")
        childAspectRatio = childAspectRatio.split("/").reduce((num, den) => num / den)
    return childAspectRatio / (2 * parentAspectRatio)
}

export function VisualizePOI ({
    x, // Between 0 and 1
    y, // Between 0 and 1
    rectangle = true,
    cross = true,
    dimming = true,
    aspectRatio = "9/16",
    rectangleColor = "#9b9b9b",
    onDrag=undefined,
}) {
    let rectangleEl = null
    if (rectangle) {
        const halfWidth = halfWidthOfRectangle(aspectRatio) * 100
        const left = Math.max(halfWidth, Math.min(100 - halfWidth, x * 100))
        rectangleEl = (
            <>
                <div className="poi-rectangle" style={{
                    aspectRatio: aspectRatio,
                    left: `${left}%`,
                    borderColor: rectangleColor,
                }}/>
                {dimming && (
                    <>
                        <div className="dimming-left" style={{width: `${left - halfWidth}%`}}/>
                        <div className="dimming-right" style={{width: `${100 - left - halfWidth}%`}}/>
                    </>
                )}
            </>
        )
    }
    let crossEl = null
    if (cross) {
        crossEl = (
            <div className="poi-cross" style={{
                left: `${x * 100}%`,
                top: `${y * 100}%`
            }}/>
        )
    }

    const onMouseDown = useCallback((e) => {
        if (typeof onDrag !== "function") return
        const target = e.target
        console.log(target)
        e.preventDefault()
        const onMouseMove = throttled((e) => {
            const boundingRect = target.getBoundingClientRect()
            const newX = Math.min(
                1,
                Math.max(0, (e.clientX - boundingRect.left) / boundingRect.width)
            )
            const newY = Math.min(
                1,
                Math.max(0, (e.clientY - boundingRect.top) / boundingRect.height)
            )

            onDrag(newX, newY)
        }, 100)

        const onMouseUp = () => {
            window.removeEventListener("mousemove", onMouseMove)
            window.removeEventListener("mouseup", onMouseUp)
        }
        onMouseMove(e)
        window.addEventListener("mousemove", onMouseMove)
        window.addEventListener("mouseup", onMouseUp)
    }, [onDrag])

    if (typeof onDrag === "function") return (
        <div className="poi-rectangle-cont" onMouseDown={onMouseDown}>
            {rectangleEl}
            {crossEl}
        </div>
    )
    return (
        <>
            {rectangleEl}
            {crossEl}
        </>
    )
}