import {useState, useMemo} from "react";
import ConfirmModal from "../../components/ConfirmModal";
import {useLoginSession} from "../../stores/loginSession";
import UploadDragAndDrop from "./UploadDragAndDrop";
import UploadVideoFileSection from "./UploadVideoFileSection";
import UploadChannelSection from "./UploadChannelSection";
import UploadCategorySection  from "./UploadCategorySection";
import UploadThumbnailSection from "./UploadThumbnailSection";
import UploadMatchAssociate from "./UploadMatchAssociate";
import UploadPrePostRollSection from "./UploadPrePostRollSection";
import UploadProgressSection from "./UploadProgressSection";
import UploadTimeOfRecordingSection from "./UploadTimeOfRecordingSection";
import PaymentInputField from "./PaymentInputField";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import "react-datepicker/dist/react-datepicker.css";
import AWS from "aws-sdk";
import {useUpdateSearchParams, showErrorMessage, useMutateByRegex} from "../../utility/Utilities";
import Backend from "../../utility/Backend";
import {AiOutlineExclamationCircle} from "react-icons/ai";
import "./Uploads.css";
import { useEffect } from "react";
import {Helmet} from "react-helmet-async";
import Page from "../../components/Page";
import {useNavigationBlock} from "../Layout/CustomRouter";
import UploadPublishPeriod from "./UploadPublishPeriod";
import Config from "../../utility/Config";

export const [
    STANDBY,
    UPLOADING,
    CANCELLED,
    COMPLETED,
    FAILED,
] = [0,1,2,3,4]

export default function UploadVideo () {

    const {token, allowAddingPayment} = useLoginSession();
    const {showFeedback} = useFeedbackMessage();
    const [, updateSearchParams,] = useUpdateSearchParams();
    const mutateByRegex = useMutateByRegex()

    // Input States
    // TODO rename some bad names
    const [chosenFile, setChosenFile] = useState(null)
    const [url, setUrl] = useState("")
    const [bitrate, setBitrate] = useState(0)
    const [resolution, setResolution] = useState(null)
    const [title, setTitle] = useState("")
    const [selectedChannel, setSelectedChannel] = useState([])
    const [tags, setTags] = useState([])
    const [uploadThumbnail, setUploadThumbnail] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [uploadedPlaylistId, setUploadedPlaylistId] = useState(null)
    const [customThumbnail, setCustomThumbnail] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const [imageFile, setImageFile] = useState(null)
    const [selectedGame, setSelectedGame] = useState(null)
    const [ppvSelected, setPpvSelected] = useState(false)
    const [subscriptionSelected, setSubscriptionSelected] = useState(false)
    const [merchant, setMerchant] = useState(null)
    const [price, setPrice] = useState(null)
    const [subscription, setSubscription] = useState(null)
    const [paymentPeriod, setPaymentPeriod] = useState(subscriptionSelected ? null : 3)
    const [paymentActive, setPaymentActive] = useState(false)
    const [selectedPreRoll, setSelectedPreRoll] = useState([])
    const [selectedPostRoll, setSelectedPostRoll] = useState([])
    const [manualTimestamp, setManualTimestamp] = useState(null)
    const [publishPeriod, setPublishPeriod] = useState(Config.uploadDefaults.publishPeriod)
    const [awsOngoingUpload, setAwsOngoingUpload] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(STANDBY)
    const [inputError, setInputError] = useState(false)
    const [uploadPromptModal, setUploadPromptModal] = useState(null)

    const isSef = Config.association === "SEF"
    // TODO better error check
    const ppvComplete = subscriptionSelected ? (ppvSelected && !!price) : (ppvSelected && !!price && !!paymentPeriod)
    const subscriptionComplete = subscriptionSelected && !!subscription
    const paymentMethodSelected = ppvComplete || subscriptionComplete
    const paymentDetailsComplete = paymentActive? (!!merchant && paymentMethodSelected) : true

    const today = useMemo(() => new Date(), [])
    const timestamp = useMemo(() => {
        if (manualTimestamp) return manualTimestamp
        if (selectedGame) return new Date(selectedGame.start_time)
        return today
    }, [selectedGame, manualTimestamp])

    useEffect(() => {
        if (selectedGame) setManualTimestamp(null)
    }, [selectedGame])

    useEffect(() => {
        if (title.length >= 5 && !tags.length <= 0) setInputError(false)
    }, [title, tags])

    useEffect(() => {
        if (uploadProgress === 1) setUploadStatus(COMPLETED)
    }, [uploadProgress])

    // Set PPV payment period to null if subscription is enabled
    useEffect(() => {
        if (!subscriptionSelected) setPaymentPeriod(3)
        else setPaymentPeriod(null)
    }, [subscription, subscriptionSelected])

    useNavigationBlock("The upload form has unsaved changes. Are you sure you wish to leave?", chosenFile !== null)

    const toUploadForm = () => updateSearchParams({"editing": "upload_form"})

    const handleSelectFile = (file) => {
        setChosenFile(file)
        toUploadForm()
    }

    const handleSelectThumbnail = (imgAndUrl) => {
        setImageUrl(imgAndUrl.imageUrl)
        setImageFile(imgAndUrl.imageFile)
    }

    const cancelUpload = async () => {
        if (awsOngoingUpload !== null) {
            awsOngoingUpload.abort(awsOngoingUpload)
            setAwsOngoingUpload(null)
            setUploadStatus(STANDBY)
        }
        if (uploadedPlaylistId) {
            await Backend.delete("/playlist/" + uploadedPlaylistId, {access_token: token})
                .then(({error}) => {
                    if (error) {
                        console.error("Failed to DELETE", error)
                        showFeedback("warning", "Failed to delete playlist, " + error)
                    } else {
                        console.log("playlist removed")
                    }
                });
        }

        setUploadStatus(STANDBY)
    }

    // TODO Change this to avoid having to reset every field manually. It's a recipe for future bugs
    //  Instead make sure the react component gets unloaded, e.g. via a router navigation
    //  Ragnar will try to fix when moving the component routing
    const resetUpload = () => {
        setUploadStatus(STANDBY)
        setChosenFile(null)
        setUrl("")
        setBitrate(0)
        setResolution(null)
        setTitle("")
        setSelectedChannel([])
        setTags([])
        setUploadProgress(0)
        setCustomThumbnail(false)
        setImageUrl(null)
        setImageFile(null)
        setSelectedGame(null)
        setMerchant(null)
        setPrice(null)
        setSubscription(null)
        setPaymentPeriod(3)
        setPaymentActive(false)
        setSelectedPreRoll([])
        setSelectedPostRoll([])
        setManualTimestamp(null)
        setPublishPeriod(Config.uploadDefaults.publishPeriod)
        setAwsOngoingUpload(null)
        setInputError(false)

        if (imageUrl?.startsWith("blob:")) URL.revokeObjectURL(imageUrl)
        if (url?.startsWith("blob:")) URL.revokeObjectURL(url)
    }

    const selectedChannelGroupsId = selectedChannel.map(channel => channel.usergroups
        .map(group => {return ({id: group.id})}))
    const combinedGroupsId = [].concat.apply([], selectedChannelGroupsId)

    const groupsToPermission = [...new Map(combinedGroupsId.map(id => [id["id"], id])).values()];

    const initiateUpload = async () => {

        if (title.length < 5) {
            setInputError(true)
            return
        }

        if (isSef && selectedChannel.length <= 0) {
            setInputError(true)
            return
        }

        if (!paymentDetailsComplete) {
            setInputError(true)
            return
        }

        if (isSef && tags.length <= 0) {
            setInputError(true)
            return
        }

        // TODO add error check when there's selected merchant but no selected price

        setUploadStatus(UPLOADING)

        let uploadedThumbnail = undefined

        if (customThumbnail) {
            let body = new FormData();
            body.append("image", imageFile);

            const {data: uploadedImageData, error: uploadImageError} = await Backend.post(
                "/userimage",
                body,
                {type: "thumbnail", access_token: token},
                {json:false}
            )

            if (uploadImageError) {
                setUploadStatus(FAILED)
                showFeedback("warning", "Failed to upload image: " + uploadImageError);
                return;
            }

            uploadedThumbnail = uploadedImageData.path
        }

        const fileExt = chosenFile.name.substring(chosenFile.name.lastIndexOf(".")).toLowerCase();

        const channelsId = selectedChannel.map((channel) => {
            return ({id: channel.id})
        })

        let isPrivate = true
        if (publishPeriod === 1) isPrivate = false

        const gameId = selectedGame? selectedGame.id : undefined

        const uploadTimestamp = new Date(timestamp)
        const uploadPlusPaymentPeriod = uploadTimestamp.setDate(uploadTimestamp.getDate() + paymentPeriod)

        let reqParams = {
            "file_extension": fileExt,
            "file_size_bytes": chosenFile.size,
            "playlist": {
                "description": title,
                "is_private": isPrivate,
                "thumbnail_url": uploadedThumbnail,
                "tags": tags,
                "channels": channelsId,
                "prerolls": selectedPreRoll.map(p => ({id: p.id})),
                "postrolls": selectedPostRoll.map(p => ({id: p.id})),
                "usergroups_view": groupsToPermission,
                "usergroups_edit": groupsToPermission,
                "usergroups_grant": groupsToPermission,
            },
            "videoasset": {
                "game_id": gameId,
                "timestamp": timestamp,
                "tags": tags,
                "channels": channelsId,
                "usergroups_view": groupsToPermission,
                "usergroups_edit": groupsToPermission,
                "usergroups_grant": groupsToPermission,
            }
        };

        if (paymentActive) {
            const ppvInPayment = ppvSelected && !!price
            const subscriptionInPayment = subscriptionSelected && !!subscription
            const paymentProps = {
                price_plans: ppvInPayment ? [price] : [],
                subscriptions: subscriptionInPayment ? [{id: subscription.id}] : [],
            }
            if (!subscriptionInPayment && ppvInPayment) {
                paymentProps.payment_required_until = new Date(uploadPlusPaymentPeriod).toISOString()
            }
            reqParams.playlist.payment = paymentProps
        }

        if (resolution !== null) reqParams["video_resolution"] = resolution
        if (bitrate > 0) reqParams["video_bitrate"] = bitrate

        console.log(reqParams);

        const {data: credentials, error} = await Backend.post(
            "/userasset/v2",
            JSON.stringify(reqParams),
            {"access_token": token},
        );

        if (error) {
            setUploadStatus(FAILED)
            return
        }

        console.log("Received user upload credentials:", credentials);

        setUploadedPlaylistId(credentials.playlist.id)
        mutateByRegex(/^\/playlist\//)

        const userUploadId = credentials["id"];
        // const videoAsset = credentials["videoasset"];
        const playlist = credentials["playlist"];

        const onUploadComplete = async (awsError) => {
            setAwsOngoingUpload(null);

            // UploadVideo to AWS S3 either succeeded or failed
            if (awsError) {
                setUploadStatus(FAILED)
                console.error("UploadVideo failed", awsError, awsError.stack);
                showFeedback("warning", "An error occurred while uploading");
                return;
            }

            console.log("Uploading user upload " + userUploadId + " finished");

            const {error} = await Backend.get(
                "/userasset/" + userUploadId + "/publish/v2",
                { "access_token": token});

            if (error) {
                setUploadStatus(FAILED)
                showFeedback("warning", "Failed to publish video: " + error);
                return;
            }

            console.log("New playlist %s created", playlist.id);
        };

        // Create a login session with the AWS SDK, using the temporary credentials provided by our backend
        const s3 = new AWS.S3({
            credentials: new AWS.Credentials(
                credentials.credentials.AccessKeyId,
                credentials.credentials.SecretAccessKey,
                credentials.credentials.SessionToken),
            region: credentials.region,
        });

        const s3Parameters = {
            Bucket: credentials.bucket,
            Key: credentials.key,
            ContentType: chosenFile.type,
            Body: chosenFile
        }

        console.log("s3Parameters", s3Parameters);

        //Start the upload to AWS S3
        const upload = s3.upload(s3Parameters,
            {partSize: 8 * 1024 * 1024, queueSize: 4},
            onUploadComplete
        );

        upload.on("httpUploadProgress", (progress) => {
            // Handle upload progress
            if (!("total" in progress) || !progress.total) {
                return;
            }
            const ratioCompleted = parseFloat(progress.loaded) / parseFloat(progress.total);
            setUploadProgress(ratioCompleted);
        });

        setAwsOngoingUpload(upload);
    }

    const closeUploadFormModal = (
        <ConfirmModal
            isOpen
            onClose={() => setUploadPromptModal(null)}
            onConfirm={resetUpload}
            cancelText="Back to form"
            confirmText="Cancel">
            <div className="confirm-icon-message">
                <div className="confirm-icon"><AiOutlineExclamationCircle/></div>
                <div className="confirm-title">Are you sure you want to cancel the upload?</div>
            </div>
        </ConfirmModal>
    )

    const uploadForm = (
        <div className="upload-page-cont">
            <form className="form-dual-page">
                <div className="input-container">
                    <label className="input-title">Video</label>
                    <UploadVideoFileSection 
                        chosenFile={chosenFile}
                        url={url}
                        setUrl={setUrl} 
                        bitrate={bitrate}
                        setBitrate={setBitrate}
                        resolution={resolution}
                        setResolution={setResolution}
                        handleSelectFile={handleSelectFile}
                        uploadThumbnail={uploadThumbnail}/>
                </div>
                <div className="input-container">
                    <label className="input-title">Title</label>
                    <input
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        placeholder="Add title here"
                        className="upload-title-input"/>
                    {showErrorMessage("Title minimum 5 characters", ((inputError && !title) || (title && title.length < 5)))}
                </div>
                <div className="input-container">
                    <label className="input-title">Channels</label>
                    <UploadChannelSection autoFill
                                          selectedChannel={selectedChannel}
                                          setSelectedChannel={setSelectedChannel} />
                    {inputError && showErrorMessage("Please add minimum 1 channel", selectedChannel.length <= 0)}
                </div>
                <div className="input-container">
                    <label className="input-title">Category</label>
                    <UploadCategorySection tags={tags} setTags={setTags}/>
                    {inputError && showErrorMessage("Please add minimum 1 category", tags.length <= 0)}
                </div>
                <div className="input-container">
                    <label className="input-title">Thumbnail</label>
                    <UploadThumbnailSection 
                        imageUrl={imageUrl} 
                        selectThumbnail={handleSelectThumbnail}
                        uploadThumbnail={uploadThumbnail} 
                        setUploadThumbnail={setUploadThumbnail}
                        customThumbnail={customThumbnail}
                        setCustomThumbnail={setCustomThumbnail}/>
                </div>
                <div className="input-container match-associate">
                    <label className="input-title">Associate with match</label>
                    <UploadMatchAssociate selectedGame={selectedGame} setSelectedGame={setSelectedGame}/>
                </div>
                {/* TODO Only show this in allsvenskan */}
                {(isSef && allowAddingPayment) && (
                    <div className="input-container">
                        <label className="input-title">Payment</label>
                        <PaymentInputField
                            ppvSelected={ppvSelected}
                            subscriptionSelected={subscriptionSelected}
                            merchant={merchant}
                            price={price}
                            subscription={subscription}
                            paymentPeriod={paymentPeriod}
                            paymentActive={paymentActive}
                            setPpvSelected={setPpvSelected}
                            setSubscriptionSelected={setSubscriptionSelected}
                            setMerchant={setMerchant} 
                            setPrice={setPrice}
                            setSubscription={setSubscription}
                            setPaymentPeriod={setPaymentPeriod}
                            setPaymentActive={setPaymentActive}
                            startTime={timestamp}/>
                        {inputError && showErrorMessage("Please complete payment details or disable payment", !paymentDetailsComplete)}
                    </div>
                )}
                <div className="input-container">
                    <label className="input-title">Pre-roll</label>
                    <UploadPrePostRollSection selected={selectedPreRoll} setSelected={setSelectedPreRoll}/>
                </div>
                <div className="input-container">
                    <label className="input-title">Post-roll</label>
                    <UploadPrePostRollSection selected={selectedPostRoll} setSelected={setSelectedPostRoll} postRoll/>
                </div>
                <div className="input-container">
                    <label className="input-title">Time of recording</label>
                    <UploadTimeOfRecordingSection timestamp={timestamp} onChange={setManualTimestamp}/>
                </div>
                <div className="input-container">
                    <label className="input-title">Published</label>
                    <UploadPublishPeriod publishPeriod={publishPeriod} setPublishPeriod={setPublishPeriod}/>
                </div>
                <div className="complete-all-fields-msg">{inputError && showErrorMessage("Please complete all required field(s) above to upload", inputError)}</div>
            </form>
            <div className="confirm-cancel-btn-cont between">
                <button type="button" onClick={initiateUpload} className="green-btn">Upload</button>
                <button type="button" onClick={() => setUploadPromptModal(closeUploadFormModal)}>Cancel</button>
            </div>
            {(uploadStatus !== STANDBY) && (
                <UploadProgressSection
                    chosenFile={chosenFile} 
                    title={title}
                    publishPeriod={publishPeriod}
                    uploadStatus={uploadStatus}
                    uploadProgress={uploadProgress} 
                    resetUpload={resetUpload} 
                    cancelUpload={cancelUpload}
                />
            )}
            {(uploadPromptModal && uploadStatus === STANDBY) && closeUploadFormModal}
        </div>
    )

    const body = chosenFile? uploadForm :
        (<div className="upload-dnd-single">
            <UploadDragAndDrop
                chosenFile={chosenFile}
                onFileChange={handleSelectFile}
                onUrlChange={setUrl}
            />
        </div>)

    return (
        <Page title="New upload">
            <Helmet>
                <title>New upload</title>
            </Helmet>
            {body}
        </Page>
    )
}