import {BsAlignEnd, BsAlignStart, BsZoomIn, BsZoomOut} from "react-icons/bs";
import {capitalizeFirstLetter, formatTimecode, getTimelineClickRatio, throttled} from "../../../../utility/Utilities";
import React from "react";
import "./EditingControls.css";
import classNames from "classnames";
import EditingTimelineNumber from "./EditingTimelineNumber";
// import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { TiArrowSortedUp } from "react-icons/ti";

export default function EditingTimeline ({
    timelineStart, // Start of the visual timeline in seconds into the original video
    timelineEnd, // End of the visual timeline in seconds into the original video
    start, // Start of selection
    end, // End of selection
    originalStart, 
    originalEnd,
    setStart,
    setEnd,
    getCurrentPlaybackTime, // Get the timestamp from the video player for playback location
    seekTo, // Function for seeking to a given second into the original video
    zoomIn = undefined, // Function for reducing the size of the timeline
    zoomOut = undefined, // Function for increasing the size of the timeline
    type="clip",
}) {

    const intervalDuration = end - start;
    const timelineDuration = timelineEnd - timelineStart;
    const startRatio = Math.min(1, start / timelineDuration);
    const endRatio = Math.min(1, end / timelineDuration);
    const style = {left: `${100*startRatio}%`, width: `${100 * (endRatio - startRatio)}%`};

    const originalStartRatio = Math.min(1, originalStart / timelineDuration) * 100
    const originalEndRatio = Math.min(1, originalEnd / timelineDuration) * 100
    const originalStartStyle = {left: `${originalStartRatio}%`} 
    const originalEndStyle = {left: `${originalEndRatio}%`} 
    
    const time = getCurrentPlaybackTime()
    // disable set start button when it reaches 2 seconds before the end of timeline
    const startMaxLimit = (timelineEnd - time < 2) && (timelineEnd - time >= 0)
    // disable set end button it reaches 2 seconds after the start of timeline
    const endMinLimit = (timelineStart - time < 2) && (timelineStart - time >= 0)

    const handleSetStartPoint = () => {
        if (startMaxLimit) return
        else setStart(time)
    }

    const handleSetEndPoint = () => {
        if (endMinLimit) return
        else setEnd(time)
    }

    function timelineMouseDown (e) {
        e.preventDefault();
        const target = e.target;
        const ratio = getTimelineClickRatio(e);
        const distanceToStart = Math.abs(ratio - startRatio);
        const distanceToEnd = Math.abs(ratio - endRatio);

        // TODO Event marker if we're editing an event ?
        const settingStartPoint = distanceToStart < distanceToEnd
        const settingEndPoint = !settingStartPoint
        const func = settingStartPoint ? setStart : setEnd;
        const onMouseMove = throttled((e) => {
            let sec = timelineStart + (getTimelineClickRatio(e, target) * timelineDuration);
            sec = settingEndPoint ? Math.min(sec, timelineEnd) : sec
            const stopStart = settingStartPoint && timelineEnd - sec < 2
            const stopEnd = settingEndPoint && sec - timelineStart < 2
            if (stopStart || stopEnd) return
            seekTo(sec);
            func(sec);
        }, 200);
        onMouseMove(e);
        const onMouseUp = () => {
            window.removeEventListener("mousemove", onMouseMove)
            window.removeEventListener("mouseup", onMouseUp)
        };
        window.addEventListener("mouseup", onMouseUp);
        window.addEventListener("mousemove", onMouseMove);
    }

    return (
        <>
            <EditingTimelineNumber timelineStart={timelineStart} timelineEnd={timelineEnd}/>
            <div className="editing-timeline" onMouseDown={timelineMouseDown}>
                <div className="editing-timeline-interval" style={style} />
            </div>
            <div className="original-start-and-end">
                <div data-tooltip="Original start point" style={originalStartStyle} className="original-start-indicator">
                    <TiArrowSortedUp/>
                </div>
                <div data-tooltip="Original end endpoint" style={originalEndStyle} className="original-end-indicator">
                    <TiArrowSortedUp/>
                </div>
            </div>
            
            <div className={classNames("editing-interval-controls", {"zoom": zoomIn && zoomOut})}>
                {zoomOut && (
                    <button className="icon-button"
                        title="Zoom Out"
                        onClick={zoomOut}>
                        <BsZoomOut />
                    </button>
                )}
                <div className="editing-interval-and-button">
                    <div className="editing-interval-time">
                        <div>{capitalizeFirstLetter(type)} start time</div>
                        {formatTimecode(timelineStart+start, true)}
                    </div>
                    <button 
                        disabled={startMaxLimit}
                        data-tooltip={`Set ${type} end`}
                        className="icon-button"
                        onClick={handleSetStartPoint}>
                        <BsAlignStart />
                    </button>
                </div>
                <div className="editing-interval-time">
                    <div>{capitalizeFirstLetter(type)} duration</div>
                    {formatTimecode(intervalDuration, true)}
                </div>
                <div className="editing-interval-and-button">
                    <button 
                        disabled={endMinLimit}
                        data-tooltip={`Set ${type} end`}
                        className="icon-button"
                        onClick={handleSetEndPoint}>
                        <BsAlignEnd />
                    </button>
                    <div className="editing-interval-time">
                        <div>{capitalizeFirstLetter(type)} end time</div>
                        {formatTimecode(timelineStart+end, true)}
                    </div>
                </div>
                {zoomIn && (
                    <button className="icon-button"
                            title="Zoom in"
                            onClick={zoomIn}>
                        <BsZoomIn />
                    </button>
                )}
            </div>
        </>
    );
}