import React, {useEffect, useReducer} from "react";
import "./EditingControls.css";
import EditingTimeline from "./EditingTimeline";
import { createPlaybackUrl } from "../../../../utility/Utilities";
import {useVideoPlayer} from "../../../../components/VideoPlayer/VideoPlayerProvider";
import {useIsMount} from "../../../../utility/Utilities";
import Config from "../../../../utility/Config";
import { useCompilation } from "../../../../stores/compilation";

// The possible start and end of the clip has been clamped against the actual length of the asset
function checkClipWithAsset (clip, asset) {
    
    const {duration, url} = createPlaybackUrl(clip, null, Config.paddingDuringEditing, 0, asset?.duration_ms);
    const originalDuration = (clip.to_timestamp - clip.from_timestamp) / 1000
    const endOfVideo = clip.to_timestamp / 1000
    const start = Config.paddingDuringEditing > (clip.from_timestamp/1000) ? clip.from_timestamp/1000 : Config.paddingDuringEditing
    const end = Math.min(start + originalDuration, start + endOfVideo)

    // Overwrite the clip, because the clip need to have the updated url
    clip.editingPlaybackUrl = url
    clip.editingDuration = duration
    
    clip.state.start = start
    clip.state.end = end
    clip.state.originalStart = start
    clip.state.originalEnd = end
    clip.state.duration = duration
    clip.state.assetChecked = true
}

function initClipReducer ({clip, asset, duration, padding}) {

    const clipDuration = (clip.to_timestamp - clip.from_timestamp) / 1000;

    // Being run when the clip hasn't been checked with asset
    if (!!clip.state && !clip.state.assetChecked) {
        checkClipWithAsset (clip, asset)
        return clip.state
    }

    if (!!clip.state && clip.state.assetChecked) {
        console.log(clip.state);
        return {
            ...clip.state,
            duration: duration,
            start: Math.min(clip.state.start, duration),
            end: Math.min(clip.state.end, duration),
        }
    }

    return {
        duration: duration,
        start: Math.min(duration, padding),
        end: Math.min(duration, padding + clipDuration),
        originalStart: Math.min(duration, padding),
        originalEnd: Math.min(duration, padding + clipDuration),
    }
}

function reducer (state, action) {
    const {duration, start, end} = state;

    switch (action.type) {
        case "reset": {
            return initClipReducer(action.props);
        }
        case "setStart": {
            const position = Math.max(0, Math.min(duration, action.position));
            return {
                ...state,
                duration,
                start: position,
                end: Math.max(end, Math.min(duration, position + 2)),
            }
        }
        case "setEnd": {
            const position = Math.max(0, Math.min(duration, action.position));
            return {
                ...state,
                duration,
                start: Math.min(start, Math.max(0, position - 2)),
                end: position,
            }
        }
        default: throw new Error("Unsupported reducer action " + action.type);
    }
}

export default function EditClipInterval ({
    clip,
    asset,
    padding=Config.paddingDuringEditing,
    withButtons=false, // If true, then onSave & onDiscard must be provided
    onSave=null,
    onDiscard=null,
    onChange=null,
    isCompilation=false,
}) {

    let {
        duration,
        seekTo,
        getCurrentTime,
        playbackUrl,
    } = useVideoPlayer();

    const {updateClipDuration} = useCompilation();

    if (duration === 0) duration = 0.0001; // Super edge case

    const currentTime = getCurrentTime()
    const isMount = useIsMount();
    const [state, dispatch] = useReducer(reducer, {clip, asset, duration, padding}, initClipReducer);
    const {start, end, originalStart, originalEnd} = state;

    useEffect(() => {
        if (!isMount) dispatch({type: "reset", props: {clip, duration, padding}});
    }, [clip, duration]);

    useEffect(() => {
        if (!isMount && onChange) {
            onChange({
                ...state,
                start,
                end,
                from_timestamp: clip.editingPlaybackFromTimestamp + parseInt(start * 1000),
                to_timestamp: clip.editingPlaybackFromTimestamp + parseInt(end * 1000),
            });
        }
        if (isCompilation) updateClipDuration(clip)
    }, [start, end]);

    // Seek for single clip
    useEffect(() => {
        if (!isCompilation) seekTo(start)
    }, [])

    // Seek for compilations, when changing the clip
    useEffect(() => {
        if (isCompilation && currentTime === 0) seekTo(start)
    }, [start, currentTime])

    function onClickSave () {
        const splitUrl = playbackUrl.split("/");
        const offsetMs = parseInt(splitUrl[splitUrl.length - 2].split(":")[1]);
        const newClip = {
            ...clip,
            from_timestamp: offsetMs + parseInt(1000 * start),
            to_timestamp: offsetMs + parseInt(1000 * end),
        }
        if (clip.from_timestamp === newClip.from_timestamp &&
            clip.to_timestamp === newClip.to_timestamp) return onDiscard();

        onSave(newClip);
    }

    return (
        <>
            <EditingTimeline
                timelineStart={0}
                timelineEnd={duration}
                originalStart={originalStart}
                originalEnd={originalEnd}
                start={start}
                end={end}
                setStart={(s) => dispatch({type: "setStart", position: s})}
                setEnd={(s) => dispatch({type: "setEnd", position: s})}
                getCurrentPlaybackTime={getCurrentTime}
                seekTo={seekTo}
            />
            {withButtons && (
                <div className="confirm-cancel-btn-cont between">
                    <button className="green-btn" onClick={onClickSave}>
                        Save
                    </button>
                    <button onClick={onDiscard}>
                        Discard
                    </button>
                </div>
            )}
        </>
    );
}