import "../VideoPlayer.css";
import {useVideoPlayer} from "../VideoPlayerProvider";
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";

/*
Inserts a container element inside the videojs player element and renders all children
inside the video overlay, allowing one to draw on top of the videoplayer.
 */
export default function VideoOverlay ({
    children,
    className
}) {
    const {playerRef} = useVideoPlayer()
    const [container, setContainer] = useState(null);

    useEffect(() => {
        if (!playerRef.current) return
        const controlBar = playerRef.current.el().querySelector(".vjs-control-bar")
        if (!controlBar) return

        const newContainer = document.createElement("div")
        let cls = "video-overlay-cont"
        if (className) cls += " " + className
        newContainer.className = cls
        controlBar.insertAdjacentElement("beforebegin", newContainer)
        setContainer(newContainer)
        return () => {
            if (newContainer && newContainer.parentNode) {
                newContainer.parentNode.removeChild(newContainer)
            }
        }
    }, [playerRef.current, className])

    if (!container) return null
    return ReactDOM.createPortal(
        <>
            {children}
        </>,
        container
    )
}