import React, {useEffect, useState} from "react"
import Backend from "../../utility/Backend";
import { useGetLeagueLogo } from "../../utility/useGetLeagueLogo";
import { showErrorMessage, useLocalStorage } from "../../utility/Utilities";
import { useUpdateSearchParams } from "../../utility/Utilities";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { BsCheck2Circle } from "react-icons/bs";
import Config from "../../utility/Config";

export function ResetPassword () {

    const [userInLocal,, resetUserInLocal] = useLocalStorage("reset_password", null)
    const baseName = window.location.pathname.split("/")[1];
    const [associationLogo, ,displayName] = useGetLeagueLogo(baseName)
    const navigate = useNavigate()
    const [searchParams, updateSearchParams] = useUpdateSearchParams();
    const resetCode = searchParams.get("code")

    const [newPassword, setNewPassword] = useState("")
    const [repeatNewPassword, setRepeatNewPassword] = useState("")
    const [resetFormError, setResetFormError] = useState(false)
    const [submitError, setSubmitError] = useState(null)
    const [showResetSuccessMessage, setShowResetSuccessMessage] = useState(false)

    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const codePatternCorrect = uuidPattern.test(resetCode)
    const newPasswordFilled = newPassword.length >= 5
    const repeatPasswordMatch = newPasswordFilled && (newPassword === repeatNewPassword)
    const resetPasswordFormCompleted = newPasswordFilled && repeatPasswordMatch && !!resetCode && codePatternCorrect

    useEffect(() => {
        if (!userInLocal && !showResetSuccessMessage) navigate("/")
    }, [])

    useEffect(() => {
        if (resetCode && !codePatternCorrect) setResetFormError(true)
    }, [resetCode])

    useEffect(() => {
        if (resetPasswordFormCompleted) setResetFormError(null)
    }, [resetPasswordFormCompleted])

    const onChangeResetCode = (code=null) => {
        updateSearchParams("code", code)
    }

    const onKeyPress = (e) => {
        // enter key
        if (e.keyCode === 13) onResetPassword()
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyPress);
        return () => {
            window.removeEventListener("keydown", onKeyPress);
        }
    })

    if (!userInLocal && !showResetSuccessMessage) return null

    const clearResetPassword = () => {
        setNewPassword("")
        setRepeatNewPassword("")
        setResetFormError(false)
        setSubmitError(false)
        onChangeResetCode()
    }

    const cancelResetPassword = () => {
        clearResetPassword()
        resetUserInLocal()
        navigate("/requestreset")
    }

    const backToLoginPage = () => {
        setShowResetSuccessMessage(false)
        navigate("/")
    }

    function onResetPassword () {

        if (submitError) setSubmitError(null)

        if (!resetCode && !codePatternCorrect) {
            setResetFormError(true)
            return
        }

        if (!newPasswordFilled) {
            setResetFormError(true)
            return
        }

        if (!repeatPasswordMatch) {
            setResetFormError(true)
            return
        }
  
        const resetPasswordProps = {
            password: newPassword
        }

        if (!Config.isDevEnvironment) {
            resetPasswordProps["redirect_url"] = `${window.location.origin}.${baseName}/passwordreset?code=`
        }

        console.log("resetting password", resetPasswordProps);

        Backend.post("/user/password/reset/" + resetCode, JSON.stringify(resetPasswordProps))
            .then(({error}) => {
                if (error) {
                    console.error("Failed to reset password", error)
                    setSubmitError(error + ". Please check and enter the correct token")
                    setNewPassword("")
                    setRepeatNewPassword("")
                } else {
                    resetUserInLocal()
                    clearResetPassword()
                    setShowResetSuccessMessage(true)
                    console.log("Reset password successful");
                }
            });
    }

    const resetPasswordForm = (
        <>
            <div className="reset-guide-message">
                We've sent a reset token. Please check your email to get the token.
            </div>
            <input
                type="text"
                onChange={(e) => onChangeResetCode(e.target.value)}
                value={resetCode || ""}
                placeholder="Password reset token"
                className="reset-password-input token"
                />
            <input
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                placeholder="Enter new password"
                className="reset-password-input"
                />
            <input
                type="password"
                onChange={(e) => setRepeatNewPassword(e.target.value)}
                value={repeatNewPassword}
                placeholder="Repeat new password"
                className="reset-password-input"
                />
            <div className="error-msg login">
                {submitError}
                {resetFormError && showErrorMessage("Wrong reset token format", (!resetCode || !codePatternCorrect))}
                {resetFormError && showErrorMessage("Password min 5 characters", !newPasswordFilled)}
                {resetFormError && showErrorMessage("Passwords don't match", !repeatPasswordMatch)}
            </div>
            <div className="confirm-cancel-btn-cont between margin-top">
                <button onClick={onResetPassword}>Reset</button>
                <button onClick={cancelResetPassword}>Cancel</button>
            </div>
        </>
    )

    const resetPasswordSuccess = (
        <div className="reset-password-message">
            <div className="reset-password-success">
                Reset password successful!
            </div>
            <BsCheck2Circle className="reset-success-icon"/>
            <div onClick={backToLoginPage} className="back-to-login">
                Back to login page
            </div>
        </div>
    )

    return (
        <div className="reset-password-page">
            <div className="login-container">
                <div className="login-logo-and-name">
                    <img src={associationLogo} className="login-association-logo" alt="logo"/>
                    <div className="login-association-name">{displayName}</div>
                </div>
                <div className="reset-password-form">
                    <div className="login-welcome">Reset password</div>
                    <div className="mam-long-name">Media Asset Management</div>
                    {showResetSuccessMessage ? resetPasswordSuccess : resetPasswordForm}
                </div>
            </div>
        </div>
    )
}

export function RequestResetPassword () {

    const [, setUserInLocal,] = useLocalStorage("reset_password", null)
    const baseName = window.location.pathname.split("/")[1];
    const [associationLogo, ,displayName] = useGetLeagueLogo(baseName)
    const navigate = useNavigate()
    
    const [emailForReset, setEmailForReset] = useState("")
    const [requestFormError, setRequestFormError] = useState(false)
    const [sendingToEmail, setSendingToEmail] = useState(false)
    const [submitError, setSubmitError] = useState(null)

    const setUserToLocalStorage = async (user) => {
        await setUserInLocal(user)
    }

    useEffect(() => {
        if (!!emailForReset) setRequestFormError(false)
    }, [emailForReset])

    const onKeyPress = (e) => {
        // enter key
        if (e.keyCode === 13) onRequestResetToken()
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyPress);
        return () => {
            window.removeEventListener("keydown", onKeyPress);
        }
    })

    const clearRequestForm = () => {
        setEmailForReset("")
        setSubmitError(null)
        setSendingToEmail(false)
    }

    const onRequestResetToken = async () => {

        if (submitError) setSubmitError(null)

        if (!emailForReset) {
            setRequestFormError(true)
            return
        }

        let resetPasswordProps = {
            email: emailForReset
        } 

        console.log("requesting reset token", resetPasswordProps);

        setSendingToEmail(true)

        Backend.post("/user/password/reset", JSON.stringify(resetPasswordProps))
            .then(async ({error}) => {
                if (error) {
                    console.error("Failed to request token", error)
                    setSubmitError(error)
                    setSendingToEmail(false)
                    return
                } else {
                    await setUserToLocalStorage(resetPasswordProps)
                    navigate("/passwordreset")
                    clearRequestForm()
                    console.log("Reset token sent to email");
                }
            });
    }

    const sendingToEmailLoading = (
        <div className="sending-to-email">
            Sending reset code to your email
            <div className="sending-to-email-loading">
                <div />
            </div>
        </div>
    )

    return (
        <div className="reset-password-page">
            <div className="login-container">
                <div className="login-logo-and-name">
                    <img src={associationLogo} className="login-association-logo" alt="logo"/>
                    <div className="login-association-name">{displayName}</div>
                </div>
                <div className="reset-password-form">
                    <div className="login-welcome">Reset password</div>
                    <div className="mam-long-name">Media Asset Management</div>
                    <div className="reset-guide-message">Please enter your email</div>
                    <input
                        type="text"
                        onChange={(e) => setEmailForReset(e.target.value)}
                        value={emailForReset}
                        placeholder="Enter email"
                        className="reset-password-input"
                        />
                    <div className="error-msg login">
                        {sendingToEmail && sendingToEmailLoading}
                        {submitError}
                        {requestFormError && showErrorMessage("Provide either email or username", !emailForReset)}
                    </div>
                    <div className="confirm-cancel-btn-cont between margin-top">
                        <button onClick={onRequestResetToken} disabled={sendingToEmail}>Submit</button>
                        <button onClick={() => window.history.back()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}