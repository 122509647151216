import React, { useState, useRef, useEffect } from "react"
import { useBackend } from "../../utility/Backend";
import classNames from "classnames"
import { ClickOutside } from "../../utility/Utilities";
import "./Payment.css"
import {TiArrowSortedDown} from "react-icons/ti";
import {FiCheckSquare, FiSquare} from "react-icons/fi";

function Subscription ({ 
    merchant,
    subscription,
    setSubscription,
    subscriptionSelected,
    setSubscriptionSelected,
}) {

    const [showSubscriptions, setShowSubscriptions] = useState(false)

    const subscriptionListRef = useRef(null)
    const dropdownListRef = useRef(null)
    ClickOutside(subscriptionListRef, setShowSubscriptions)
    ClickOutside(dropdownListRef, setShowSubscriptions)

    const {data: subscriptionsData} = useBackend(merchant?.id ? "/cee_merchant/" + merchant.id + "/subscription" : null, {});

    const subscriptionsOptions = subscriptionsData?.subscriptions || []
    const subscriptionPrice = subscription?.price_plans[0]

    const noMerchants = !merchant
    const gettingSubscription = !!merchant && !subscriptionsData
    const noSubscriptions = !!merchant && !!subscriptionsData && subscriptionsOptions?.length === 0
    const hasSubscriptions = !!merchant && subscriptionsOptions?.length !== 0
    const showSubscriptionOptions = hasSubscriptions && subscriptionSelected
    const disableSubscriptionSelect = noMerchants || noSubscriptions

    // Disable subscription when changing merchant and the merchant has no subscription product
    useEffect(() => {
        if (subscriptionSelected && disableSubscriptionSelect) setSubscriptionSelected(false)
    }, [merchant, disableSubscriptionSelect])

    const onEnableSubscription = () => {
        if (disableSubscriptionSelect) return
        setSubscriptionSelected(!subscriptionSelected)
    }

    const handleShowSubscriptions = () => {
        setShowSubscriptions(!showSubscriptions)
    }

    const handleSelectSubscription = (subscription) => {
        setSubscription(subscription)
        setShowSubscriptions(false)
    }

    let subscriptionOptions

    if (noMerchants) subscriptionOptions = (
        <div className="select-merchant-for-price">
            Select merchant to show subscriptions
        </div>
    )

    if (gettingSubscription) subscriptionOptions = (
        <div className="select-merchant-for-price">
            Getting subscription...
        </div>
    )
    
    if (noSubscriptions) subscriptionOptions = (
        <div className="select-merchant-for-price">
            No available subscription
        </div>
    )

    if (showSubscriptionOptions) subscriptionOptions = (
        <div ref={subscriptionListRef} className="input-container payment-dropdown">
            <label className="input-title">Subscription</label>
            <div ref={dropdownListRef} className="upload-dropdown-cont">
                <div onClick={handleShowSubscriptions} className="upload-dropdown-title">
                    {subscription? 
                        `${subscription.name} - ${subscriptionPrice.price_amount} ${subscriptionPrice.price_currency}`
                    : "Select subscription"}
                    <TiArrowSortedDown/>
                </div>
                {showSubscriptions && (
                    <div className="upload-dropdown-list">
                        <ul className="payment-option-list">
                            {subscriptionsOptions.map((s) => {
                                const price = s.price_plans[0]
                                return (
                                    <li
                                        key={s.id}
                                        onClick={() => handleSelectSubscription(s)}
                                        className={classNames("payment-option-single", {"active": s.id === subscription?.id})}
                                        >
                                        {s.name} - {price.price_amount} {price.price_currency}
                                    </li>
                                )
                            })}
                        </ul> 
                    </div>        
                )}
            </div>
        </div>
    )

    return (
        <div className="payment-type">
            <div onClick={onEnableSubscription} className={classNames("payment-method-title", {"disabled": disableSubscriptionSelect})}>
                {/* Change to native checkbox in MAM */}
                {subscriptionSelected?
                    <FiCheckSquare className="check-box-icon"/> :
                    <FiSquare className="check-box-icon"/>}
                Subscription
            </div>
            {subscriptionOptions}
        </div>
    )
}

export default Subscription