import React, {useCallback, useEffect, useReducer, useState} from "react";
import VideoActionOptions from "./VideoActionOptions"
import VideoActionModal from "./VideoActionModal";
import {useSWRConfig} from "swr";
import {useLoginSession} from "../stores/loginSession";
import {useFeedbackMessage} from "../stores/FeedbackMessage";
import ConfirmModal from "./ConfirmModal";
import CustomModal from "./CustomModal";
import Backend, {getFullPath} from "../utility/Backend";
import {useMutateByRegex} from "../utility/Utilities";
import "../components/css/VideoAction.css";
import {useCompilation} from "../stores/compilation";
import {MdListAlt} from "react-icons/md";

const defaultState = {
    type: "",
    title:"",
    exportWarning: false,
    exportWarningMsg: "",
}

const actionReducer = (state, action) => {
    switch (action.type) {
        case "download":
            return {
                ...state,
                status: false,
                type: action.type,
                title: "Download following video(s)",
            }
        case "list":
            return {
                ...state,
                status: false,
                type: action.type,
                title: "List following video(s)",
            }
        case "unList":
            return {
                ...state,
                status: false,
                type: action.type,
                title: "Unlist following video(s)",
            }
        case "feature":
            return {
                ...state,
                status: false,
                type: action.type,
                title: "Feature following video(s)",
            }
        case "unFeature":
            return {
                ...state,
                status: false,
                type: action.type,
                title: "Unfeature following video(s)",
            }
        case "voting":
            return {
                ...state,
                status: false,
                type: action.type,
                title: "add below video(s) to voting ballot",
            }
        case "exportOptionWarning":
            return {
                ...state,
                exportWarning: true,
                exportWarningMsg: "Please select below options"
            }
        case "exportWarningClear":
            return {
                ...state,
                exportWarning: false,
                exportWarningMsg: ""
            }
        case "failed":
            return {
                ...state,
                status: true,
            }
        case "clear":
            return {
                type: "",
                title:"",
                exportWarning: false,
                exportWarningMsg: "",
            }
        default:
            return state;
    }
};

const VideoAction = ({
    selectedVideos,
    clearSelectedList,
    showSelectAll,
    onSelectAllVideosInPage,
    allVideoInPageSelected,
}) => {

    const {token, user} = useLoginSession();
    const { mutate } = useSWRConfig();
    const {showFeedback} = useFeedbackMessage();
    const {clips, compilationExists, newCompilation, addToCompilation} = useCompilation();
    const mutateByRegex = useMutateByRegex()

    const [videosForAction, setVideosForAction] = useState([]);
    const [actionModalVisible, setActionModalVisible] = useState(false);
    const [state, dispatch] = useReducer(actionReducer, defaultState);
    const [failedId, setFailedId] = useState([]); // TEMPORARY success/failed dummy
    const [createCompilation, setCreateCompilation] = useState(false);
    const [isCreatingCompilation, setIsCreatingCompilation] = useState(false)

    useEffect(() => {
        if (isCreatingCompilation && clips) setIsCreatingCompilation(false)
    }, [clips])

    const openCreateCompilation = () => {
        setCreateCompilation(true);
    }

    const closeCreateCompilation = () => {
        setCreateCompilation(false);
    }

    const chooseVideoAction = (actionType) => {
        let readyVideos = Array.from(selectedVideos.values());
        setVideosForAction(readyVideos);
        setActionModalVisible(true);
        chosenAction(actionType);
    }

    const removeVideoFromModal = (id) => {
        const updatedActionList = videosForAction.filter((video) => video.id !== id);
        setVideosForAction(updatedActionList);
    }

    const resetActionModal = () => {
        setActionModalVisible(false);
        setVideosForAction([]);
        setFailedId([]);
        dispatch ({type: "clear"});
    };

    const toCompilation = () => {
        let readyVideos = Array.from(selectedVideos.values());
        addToCompilation(readyVideos);
        showFeedback("success", readyVideos.length + " video(s) added to compilation successfully!");
    }

    const chosenAction = (type) => {
        if (type === "download"){
            dispatch({ type: type });
        }
        if (type === "list"){
            dispatch({ type: type });
        }
        if (type === "unList"){
            dispatch({ type: type });
        }
        if (type === "feature"){
            dispatch({ type: type });
        }
        if (type === "unFeature"){
            dispatch({ type: type });
        }
        if (type === "voting"){
            dispatch({ type: type });
        }
        return null;
    }

    const createNewCompilation = () => {
        setIsCreatingCompilation(true)
        newCompilation(selectedVideos);
        closeCreateCompilation();
        clearSelectedList();
    }

    const exportVideo = async (playlist, type, format, audio, server) => {
        await new Promise(r => setTimeout(r, 250));
        const successRate = Math.random();
        if (successRate < .8) {
            console.log(playlist.id, type, format, audio, server);
            resetActionModal();
            showFeedback("success", videosForAction.length + " video(s) exported successfully!");
        }
        else {
            setFailedId((prevId) => {
                prevId.push(playlist.id)
                return prevId
            })
            dispatch ({ type: "failed" });
        }
    }

    const emailVideo = async (playlist) => {

        // /playlist.m3u8/<string:playlist_query>/<int:bw>/<string:video_name>.mp4?access_token=12345
        const playlistQuery = playlist.video_url.match(/playlist\.m3u8\/(.+)\/Manifest\.m3u8/)[1];
        const bitrate = 10000000;
        const videoName = playlist.id;
        const path = `/playlist.m3u8/${playlistQuery}/${bitrate}/${videoName}.mp4`;
        const body = {"type": "s3"};

        console.log(path, JSON.stringify(body), {access_token: token});

        Backend.post(path, JSON.stringify(body), {access_token: token})
            .then(({error}) => {
                if (error) {
                    console.error("Failed to POST", error);
                    setFailedId((prevId) => {
                        prevId.push(playlist.id);
                        return prevId;
                    });
                    dispatch ({ type: "failed" });
                    showFeedback("warning", "Failed to email, " +  error);
                } else {
                    console.log("email sent");
                    resetActionModal();
                    showFeedback("success", videosForAction.length + " download link(s) emailed to " + user.email);
                }
            });
    }

    const exportAndEmail = (type, format, audio, server, email) => {

        if ((type === null) ||
            (format === null) ||
            (audio === null) ||
            (type === "export" && server === null) ||
            (type === "email" && email === null)) {
            dispatch ({ type: "exportOptionWarning" });
            return;
        }

        for (let i = 0; i < videosForAction.length; i++) {
            if (type === "export"){
                exportVideo(videosForAction[i], type, format, audio, server);
            }
            if (type === "email"){
                emailVideo(videosForAction[i], type, format, audio, email);
            }
        }
        clearSelectedList();
    }

    const backendCall = (playlist, edits) => {
        const query = {access_token: token};
        const path = "/playlist/" + playlist.id;
        Backend.put(path, JSON.stringify(edits), query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to PUT", error);
                    dispatch({type: "clear"});
                    showFeedback("warning", "Failed to do the action, " + error);
                } else {
                    mutate(getFullPath(path, {include_featured_flag: true}));
                }
            });
        return false;
    }

    const listUnListFeature = () => {
        let edits = null;
        let msg = null;

        if (state.type === "list"){
            edits = {is_private: false};
            msg = "listed";
        }
        if (state.type === "unList"){
            edits = {is_private: true};
            msg = "unlisted";
        }
        if (state.type === "feature"){
            edits = {featured: true};
            msg = "featured";
        }
        if (state.type === "unFeature"){
            edits = {featured: false};
            msg = "unfeatured";
        }

        console.log("Saving data:", JSON.stringify(edits));

        for (let i = 0; i < videosForAction.length; i++) {
            backendCall(videosForAction[i], edits);
        }
        resetActionModal();
        clearSelectedList();
        mutateByRegex(/^\/(playlist)|(event)|(game\/\d+\/events)/)
        showFeedback("success", videosForAction.length + " video(s) " + msg + " successfully!");
    }

    const toVotingBallot = () => {
        resetActionModal();
        clearSelectedList();
        showFeedback("success", videosForAction.length + " video(s) added to voting ballot successfully!");
    }

    const clearWarning = useCallback(() => {
        dispatch ({ type: "exportWarningClear" });
    }, []);

    useEffect(() => {
        if (videosForAction.length <= 0) return setActionModalVisible(false);
        return videosForAction;
    }, [videosForAction]);

    const createNewCompilationModal = (
        <ConfirmModal 
            isOpen
            onClose={closeCreateCompilation} 
            onConfirm={createNewCompilation} 
            cancelText="Cancel" 
            confirmText="Create"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><MdListAlt/></div>
                <div className="confirm-title">Are you sure you want to create new compilation with {selectedVideos?.size} video(s)?</div>
            </div>
        </ConfirmModal>
    );

    const creatingCompilationIndicator = (
        <CustomModal
            isOpen={isCreatingCompilation}
            onRequestClose={() => setIsCreatingCompilation(false)}
            className="transparent-modal">
            <div className="percentage-progress-status">
                Creating compilation
                <div className="percentage-progress-title-dot">.</div>
                <div className="percentage-progress-title-dot">.</div>
                <div className="percentage-progress-title-dot">.</div>
            </div>
        </CustomModal>
    )

    return (
        <>
            <VideoActionOptions
                state={state}
                chooseVideoAction={chooseVideoAction}
                compilationExists={compilationExists}
                toCompilation={toCompilation}
                createNewCompilation={openCreateCompilation}
                selectedVideos={selectedVideos}
                clearSelectedList={clearSelectedList}
                showSelectAll={showSelectAll}
                onSelectAllVideosInPage={onSelectAllVideosInPage}
                allVideoInPageSelected={allVideoInPageSelected}
                />
            {actionModalVisible && (
                <VideoActionModal 
                    state={state}
                    videosForAction={videosForAction}
                    removeVideoFromModal={removeVideoFromModal}
                    resetActionModal={resetActionModal}
                    exportAndEmail={exportAndEmail}
                    listUnListFeature={listUnListFeature}
                    toVotingBallot={toVotingBallot}
                    clearWarning={clearWarning}
                    failedId={failedId}
                    />
            )}
            {createCompilation && createNewCompilationModal}
            {creatingCompilationIndicator}
        </>
    );
}

export default VideoAction;