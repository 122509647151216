import React, {useEffect, useState, useRef} from "react"
import "./Search.css"
import TagBasedSearch from "./TagBasedSearch";
import FreeSearch from "./FreeSearch";
import {TiArrowSortedDown} from "react-icons/ti";
import classNames from "classnames";
import { capitalizeFirstLetter } from "../utility/Utilities";
import { ClickOutside } from "../utility/Utilities";

function Search () {

    const searchModelRef = useRef(null)
    const [searchModel, setSearchModel] = useState("tag-based-search")
    const [searchModelOptionsOpen, setSearchModelOptionsOpen] = useState(false)

    useEffect(() => {
        if (searchModelOptionsOpen) setSearchModelOptionsOpen(false)
    }, [searchModel])

    ClickOutside(searchModelRef, setSearchModelOptionsOpen)

    let searchEngine

    if (searchModel === "tag-based-search") {
        searchEngine = <TagBasedSearch />
    }

    if (searchModel === "free-search") {
        searchEngine = <FreeSearch />
    }

    const searchModelOptions = (
        <div ref={searchModelRef} className="search-model-cont">
            <div onClick={() => setSearchModelOptionsOpen(!searchModelOptionsOpen)} className="selected-search-model">
                {capitalizeFirstLetter(searchModel)}
                <TiArrowSortedDown/>
            </div>
            {searchModelOptionsOpen && (
                <div className="search-model-options">
                    <div 
                        onClick={() => setSearchModel("tag-based-search")} 
                        className={classNames("search-model-option-single", {"active" : searchModel === "tag-based-search"})}>
                        Tag-based search
                    </div>
                    <div 
                        onClick={() => setSearchModel("free-search")} 
                        className={classNames("search-model-option-single", {"active" : searchModel === "free-search"})}>
                        Free search
                    </div>
                </div>
            )}
        </div>
    )

    return (
        <div className="search-cont">
            {searchModelOptions}
            {searchEngine}
        </div>
    )
}

export default Search