import React, { useState, useRef, useEffect } from "react"
import { useBackend } from "../../utility/Backend";
import classNames from "classnames"
import { ClickOutside } from "../../utility/Utilities";
import "./Payment.css"
import { getDateFormat } from "../../utility/Utilities";
import {TiArrowSortedDown} from "react-icons/ti";
import {FiCheckSquare, FiSquare} from "react-icons/fi";

function PricePlansDropdown ({pricesData, price, setPrice}) {

    const [showPrices, setShowPrices] = useState(false)

    const dropdownListRef = useRef(null)
    ClickOutside(dropdownListRef, setShowPrices)
    
    const prices = pricesData?.price_plans || []

    const handleShowPrices = () => {
        setShowPrices(!showPrices)
    }

    const handleSelectPrice = (price) => {
        setPrice(price)
        setShowPrices(false)
    }

    return (
        <div ref={dropdownListRef} className="upload-dropdown-cont">
            <div onClick={handleShowPrices} className="upload-dropdown-title">
                {price? price.name : "Select price"}
                <TiArrowSortedDown/>
            </div>
            {showPrices && (
                <div className="upload-dropdown-list">
                    <ul className="payment-option-list">
                        {prices.map((p) => {
                            return (
                                <li
                                    key={p.name}
                                    onClick={() => handleSelectPrice(p)}
                                    className={classNames("payment-option-single", {"active": p.price_amount === price?.price_amount})}
                                    >
                                    {p.name}
                                </li>
                            )
                        })}
                    </ul> 
                </div>        
            )}
        </div>
    )
}

function PayPerView ({ 
    ppvSelected,
    setPpvSelected,
    paymentPeriod,
    setPaymentPeriod, 
    price,
    setPrice,
    subscriptionSelected,
    merchant,
    startTime,
    editPayment=false,
}) {
    
    const [showPeriods, setShowPeriods] = useState(false)

    const periodListRef = useRef(null)
    ClickOutside(periodListRef, setShowPeriods)

    const merchantId = merchant?.id
    const {data: pricesData} = useBackend(merchantId ? "/cee_merchant/" + merchantId + "/price_plan" : "", {});

    const ppvProduct = pricesData?.price_plans || []

    const noMerchants = !merchant
    const gettingPPV = !!merchant && !pricesData
    const noPPV = !!merchant && !!pricesData && ppvProduct.length === 0
    const hasPPV = !!merchant && ppvProduct.length !== 0
    const showPPVOptions = hasPPV && ppvSelected
    const disablePPVSelect = noMerchants || noPPV

    useEffect(() => {
        if (editPayment) return
        if (!!merchant && hasPPV && !ppvSelected) setPpvSelected(true)
    }, [merchant])

    // Disable PPV when the merchant has no PPV products
    useEffect(() => {
        if (disablePPVSelect) setPpvSelected(false)
    }, [disablePPVSelect])

    const handleShowPeriods = () => {
        if (subscriptionSelected) return null
        else setShowPeriods(!showPeriods)
    }

    const handleSelectPeriod = (period) => {
        setPaymentPeriod(period)
        setShowPeriods(false)
    }

    const handleSelectPPV = () => {
        if (disablePPVSelect) return
        setPpvSelected(!ppvSelected)
    }

    let startDate = new Date(startTime)
    startDate.setDate(startDate.getDate() + paymentPeriod)
    const periodsList = [3, 7, 30]
    
    let ppvOptions

    if (noMerchants) ppvOptions = (
        <div className="select-merchant-for-price">
            Select merchant to show pay-per-view
        </div>
    )

    if (gettingPPV) ppvOptions = (
        <div className="select-merchant-for-price">
            Getting PPV...
        </div>
    )
    
    if (noPPV) ppvOptions = (
        <div className="select-merchant-for-price">
            No available price
        </div>
    )

    if (showPPVOptions) ppvOptions = (
        <div className="payment-ppv">
            <div className="input-container payment-dropdown">
                <label className="input-title">Price</label>
                <PricePlansDropdown 
                    pricesData={pricesData}
                    price={price} 
                    setPrice={setPrice}/>
            </div>
            <div ref={periodListRef} className={classNames("input-container payment-dropdown", {
                "disabled": subscriptionSelected,
            })}>
                <label className="input-title">Payment required for</label>
                <div onClick={handleShowPeriods} className="upload-dropdown-cont">
                    <div className="upload-dropdown-title">
                        {paymentPeriod? `${paymentPeriod} days` : "Select period"}
                        <TiArrowSortedDown/>
                    </div>
                    {!!paymentPeriod && (
                        <div className="payment-period-until">Until {getDateFormat(startDate, true, true)}</div>
                    )}
                    {showPeriods && (
                        <div className="upload-dropdown-list">
                            <ul className="payment-option-list">
                                {periodsList.map((p) => {
                                    return (
                                        <li
                                            key={p}
                                            // TODO always 3 days for now
                                            onClick={() => handleSelectPeriod(p)}
                                            className={classNames("payment-option-single", {"active": p === paymentPeriod})}
                                            >
                                            {p} days
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

    return (
        <div className="payment-type">
            <div onClick={handleSelectPPV} className={classNames("payment-method-title", {"disabled": disablePPVSelect})}>
                {ppvSelected?
                    <FiCheckSquare className="check-box-icon"/> :
                    <FiSquare className="check-box-icon"/>}
                Pay-per-view
            </div>
            {ppvOptions}
        </div>
    )
}

export default PayPerView