import React, {useState} from "react"
import Config from "../../../utility/Config";
import Backend from "../../../utility/Backend";
import EditStream from "./EditStream";
import moment from "moment";
import { useMutateByRegex } from "../../../utility/Utilities";
import {getDateFormat} from "../../../utility/Utilities";
import {useLoginSession} from "../../../stores/loginSession";
import {useFeedbackMessage} from "../../../stores/FeedbackMessage";
import ConfirmModal from "../../../components/ConfirmModal";
import { streamDisplayDuration } from "./StreamDetails";
import { useUpdateSearchParams } from "../../../utility/Utilities";
import {FiEdit3} from "react-icons/fi";
import {FaTrashAlt} from "react-icons/fa";
import "../../../DataInfoUtility.css"

function StreamMetadata ({singleStream, playlistMetadata=false}) {

    const {token} = useLoginSession();
    const {showFeedback} = useFeedbackMessage();
    const mutateByRegex = useMutateByRegex();

    const [isEditStreamOpen, setIsEditStreamOpen] = useState(false);
    const [isDeleteStreamOpen, setIsDeleteStreamOpen] = useState(false);

    const [searchParams, updateSearchParams,] = useUpdateSearchParams();
    const streamParam = searchParams.get("stream");

    if (!singleStream || singleStream.length === 0) return null

    let stream, streamServer, streamTypeName, streamApiUrl

    if (singleStream.rtmp_stream_key) {
        stream = singleStream.rtmp_stream_key
        streamServer = singleStream.rtmp_stream_key.rtmp_url
        streamTypeName = "RTMP"
        streamApiUrl = "/rtmp_stream_key"
    }

    if (singleStream.srt_stream_key) {
        stream = singleStream.srt_stream_key
        streamServer = singleStream.srt_stream_key.srt_url
        streamTypeName = "SRT"
        streamApiUrl = "/srt_stream_key"
    }

    // StreamMetadata in playlist section / club content and the stream is finished
    const streamFinished = new Date(stream.valid_to) < new Date()
    if (playlistMetadata && streamFinished) return null

    const streamPlaylist = singleStream.playlist
    const isLive = streamPlaylist.is_live

    const streamDuration = new Date(stream.valid_to) - new Date(stream.valid_from);
    const formattedValidFromHour = moment(new Date(stream.valid_from)).format("HH:mm");
    const formattedValidToHour = moment(stream.valid_to).format("HH:mm");
    const sameDay = (new Date(stream.valid_to).getDate() - new Date(stream.valid_from).getDate()) === 0;  

    const expiredStream = new Date(stream.valid_to) < new Date();

    const deleteStream = () => {
        const query = {access_token: token}

        Backend.delete("/live_ingest/" + singleStream.id, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error);
                    showFeedback("warning", "Failed to delete stream, " + error);
                } else {
                    if (streamParam === singleStream.id) updateSearchParams("stream")
                    mutateByRegex(/^\/|(live_ingest)/)
                    showFeedback("success", "Stream deleted successfully!")
                    console.log("Stream deleted")
                }
            });
        return false;
    }

    const deleteStreamModal = (
        <ConfirmModal 
            isOpen 
            onClose={() => setIsDeleteStreamOpen(false)} 
            onConfirm={deleteStream} 
            confirmText = "Delete"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><FaTrashAlt/></div>
                <div className="confirm-title">Do you really want to delete {singleStream.stream_key} stream?</div>
            </div>
        </ConfirmModal>
    );
    
    const streamDetails = (
        <div className="data-info-cont">
            <div className="data-info-head-title">Stream details</div>
            <div className="data-info">
                <div className="data-info-title">Quality profile</div>
                <div className="data-info-value">
                    {Config.streamProfileMapping[stream.profile] || stream.profile}
                </div>
            </div>
            <div className="data-info">
                <div className="data-info-title">{streamTypeName} Server</div>
                <div className="data-info-value">{streamServer}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Key</div>
                <div className="data-info-value">{stream.stream_key? stream.stream_key : "-"}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Full {streamTypeName} url</div>
                <div className="data-info-value">{streamServer + stream.stream_key}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Broadcast start</div>
                <div className="data-info-value">{getDateFormat(singleStream.broadcast_start, true, true)}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">{sameDay? "Ingest period" : "Ingest from"}</div>
                <div className="data-info-value">
                    {sameDay? (
                        <div className="sd-details">
                            {formattedValidFromHour} to {formattedValidToHour}
                        </div>
                    ) : (
                        <div className="sd-details">{getDateFormat(stream.valid_from)}, {formattedValidFromHour}</div>
                    )}
                </div>
            </div>
            {!sameDay && (
                <div className="data-info">
                    <div className="data-info-title">Ingest to</div>
                    <div className="data-info-value">{getDateFormat(stream.valid_to)}, {formattedValidToHour}</div>
                </div>
            )}
            <div className="data-info">
                <div className="data-info-title">Ingest duration</div>
                <div className="data-info-value sd-duration">{streamDisplayDuration(streamDuration)}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">User</div>
                <div className="data-info-user">{stream.user.name}</div>
            </div>
            <div className="confirm-cancel-btn-cont between margin-top">
                <button type="button" disabled={expiredStream} onClick={() => setIsEditStreamOpen(true)}>
                    <FiEdit3 className="icon-in-btn"/>
                    Edit Stream
                </button>
                <button type="button" disabled={isLive} onClick={() => setIsDeleteStreamOpen(true)} className="red-btn">
                    <FaTrashAlt className="icon-in-btn"/>
                    Delete Stream
                </button>
            </div>
        </div>
    );

    return (
        <>
            {streamDetails}
            {isEditStreamOpen && (
                <EditStream 
                    singleStream={singleStream} 
                    onClose={() => setIsEditStreamOpen(false)}
                    isLive={isLive}
                    streamApiUrl={streamApiUrl}/>
            )}
            {isDeleteStreamOpen && deleteStreamModal}
        </>
    )
}

export default StreamMetadata