import React, {useState, useEffect, useReducer, useRef, useMemo} from "react";
import Config from "../../../utility/Config";
import Backend from "../../../utility/Backend";
import {useLoginSession} from "../../../stores/loginSession";
import { useFeedbackMessage } from "../../../stores/FeedbackMessage";
import { useCompilation } from "../../../stores/compilation";
import ThumbnailSelector from "../../../components/ThumbnailSelector";
import CustomModal from "../../../components/CustomModal";
import UploadChannelSection from "../../UploadVideo/UploadChannelSection";
import UploadCategorySection from "../../UploadVideo/UploadCategorySection";
import UploadMatchAssociate from "../../UploadVideo/UploadMatchAssociate";
import UploadPrePostRollSection from "../../UploadVideo/UploadPrePostRollSection";
import UploadTimeOfRecordingSection from "../../UploadVideo/UploadTimeOfRecordingSection";
import PaymentInputField from "../../UploadVideo/PaymentInputField";
import classNames from "classnames";
import {showErrorMessage, useMutateByRegex} from "../../../utility/Utilities";
import "./MetadataSection.css";
import {FiCheckSquare, FiSquare} from "react-icons/fi";
import {BsInfoCircle} from "react-icons/bs";

function initializeReducer ({playlist, asset, payment}) {

    const isSef = Config.association === "SEF"
    const ppv = isSef && payment?.price_plans[0]
    const subscription = isSef && payment?.subscriptions[0]
    const startTime = new Date(playlist.recording_timestamp).getTime()
    const paymentRequiredUntil = payment?.payment_required_until && new Date(payment.payment_required_until).getTime()
    const paymentPeriod = paymentRequiredUntil && Math.ceil((paymentRequiredUntil - startTime) / 86400000)

    return {
        playlist: playlist,
        asset: asset,

        // Playlist properties
        description: playlist.description,
        is_private: playlist.is_private,
        channels: playlist.channels,
        prerolls: playlist.prerolls || [],
        postrolls: playlist.postrolls || [],
        thumbnail_image: null,
        thumbnail_url: playlist.thumbnail_url,

        // PlaylistClip properties
        tags: playlist.events[0].tags, // Note, we cannot edit tags of compilations

        // Asset properties
        open_for_playlists: asset?.open_for_playlists,
        game: asset?.game,
        start_timestamp: asset ? new Date(asset.start_timestamp) : new Date(),

        // Payment properties
        merchant: ppv?.merchant || subscription?.merchant || null,
        subscription: subscription || null,
        price: ppv || null,
        payment_period: paymentPeriod,
        hasActivePayment: !!ppv || !!subscription,
    }
}

function reducer (state, action) {
    switch (action.type) {
        case "reset": return initializeReducer(action.payload)
        case "uploadThumbnail": return {
            ...state,
            thumbnail_url: action.payload.imageUrl,
            thumbnail_image: action.payload.imageFile,
        }
        case "autoGeneratedThumbnail": return {
            ...state,
            thumbnail_url: null,
            thumbnail_image: null,
        }
        default: {
            if (state.hasOwnProperty(action.type))
                return {...state, [action.type]: action.payload}
            return state
        }
    }
}

export default function EditVideoMetadata ({
    playlist,
    asset=null,
    payment=null,
    stream=null,
    onClose,
}) {

    const allowAssetEdit = !!playlist.master_videoasset
    if (!allowAssetEdit) asset = null
    
    const {token, allowAddingPayment} = useLoginSession();
    const {showFeedback} = useFeedbackMessage();
    const mutateByRegex = useMutateByRegex()
    
    const [state, dispatch] = useReducer(reducer, {playlist, asset, payment}, initializeReducer);
    const descriptionRef = useRef()
    const {playlistId: compilationId, setCompilationInfo} = useCompilation()

    const [ppvSelected, setPpvSelected] = useState(!!state.price)
    const [subscriptionSelected, setSubscriptionSelected] = useState(!!state.subscription)
    const [hasInputError, setHasInputError] = useState(false);

    const isSef = Config.association === "SEF"
    const hasChannels = playlist.channels?.length > 0
    const allowTagEdit = allowAssetEdit && playlist.events.length === 1 // Note, we cannot edit tags of compilations
    const invalidDescription = state.description === "" || state.description.length < 5 || state.description.length > 90
    const startTime = stream? stream.broadcast_start : playlist.recording_timestamp
    const originalPaymentPeriod = useMemo(() => {return state.payment_period}, [])
    
    // TODO better error check
    const ppvComplete = subscriptionSelected ? (ppvSelected && !!state.price) : (ppvSelected && !!state.price && !!state.payment_period)
    const subscriptionComplete = subscriptionSelected && !!state.subscription
    const paymentMethodSelected = ppvComplete || subscriptionComplete
    const paymentDetailsComplete = state.hasActivePayment ? (!!state.merchant && paymentMethodSelected) : true

    useEffect(() => {
        // Playlist may get refreshed from backend after initial render
        // Typically right after page render though, so should be safe
        dispatch({type: "reset", payload: {playlist, asset, payment}});
    }, [playlist, asset, payment]);

    useEffect(() => {
        if (invalidDescription) setHasInputError(true)
    }, [isSef, invalidDescription, state.tags])

    useEffect(() => {
        if (!state.hasActivePayment) {
            if (!invalidDescription) setHasInputError(false)
        }
        if (state.hasActivePayment) {
            if (!invalidDescription && paymentDetailsComplete) setHasInputError(false)
        }
    }, [invalidDescription, paymentDetailsComplete, state.channels, state.tags, state.merchant, state.price])

    // Set PPV payment period to null if subscription is enabled
    // and set it back to the original PPV payment period when subscription is disabled
    useEffect(() => {
        if (subscriptionSelected) handlePaymentPeriod(null)
        else handlePaymentPeriod(originalPaymentPeriod)
    }, [state.subscription, subscriptionSelected])

    function setThumbnail (newUrl) {
        if (!newUrl) dispatch({type: "autoGeneratedThumbnail"})
        if (typeof newUrl === "object") dispatch({type: "uploadThumbnail", payload: newUrl});
        else dispatch({type: "thumbnail_url", payload: newUrl});
    }

    function updateDate (date) {
        dispatch({type: "start_timestamp", payload: date})
    }

    function handlePaymentActivation (value) {
        if (value) dispatch({type: "hasActivePayment", payload: true})
        else dispatch({type: "hasActivePayment", payload: false})
    }

    function handlePaymentPeriod (value) {
        dispatch({type: "payment_period", payload: value})
    }

    function onClickClose () {
        if (state.thumbnail_url?.startsWith("blob:")) URL.revokeObjectURL(state.thumbnail_url)
        onClose()
    }

    async function onClickSubmit () {

        if (invalidDescription) {
            if (descriptionRef.current) descriptionRef.current.scrollIntoView()
            return
        }

        if (!paymentDetailsComplete) {
            setHasInputError(true)
            return
        }

        // POST for customized thumbnail
        let thumbnailUrl = state.thumbnail_url
        if (state.thumbnail_image) {
            let body = new FormData();
            body.append("image", state.thumbnail_image);

            const {data: uploadedImageData, error: uploadImageError} = await Backend.post(
                "/userimage",
                body,
                {type: "thumbnail", access_token: token},
                {json:false}
            )

            if (uploadImageError) {
                showFeedback("warning", "Failed to upload image: " + uploadImageError)
                return
            }
            thumbnailUrl = uploadedImageData.path
        }

        const playlistPutData = {}
        if (state.description !== playlist.description)
            playlistPutData.description = state.description
        if (state.is_private !== playlist.is_private)
            playlistPutData.is_private = state.is_private
        if (state.channels !== playlist.channels)
            playlistPutData.channels = state.channels
        if (state.prerolls !== playlist.prerolls)
            playlistPutData.prerolls = state.prerolls.map(p => ({id: p.id}))
        if (state.postrolls !== playlist.postrolls)
            playlistPutData.postrolls = state.postrolls.map(p => ({id: p.id}))
        if (thumbnailUrl !== playlist.thumbnail_url)
            playlistPutData.thumbnail_url = thumbnailUrl
        if (!thumbnailUrl)
            playlistPutData.thumbnail_url = null

        if (allowTagEdit && playlist.events[0].tags !== state.tags) {
            // NOTE: allowTagEdit is only legal for non-compilations with exactly 1 event
            const ev = playlist.events[0]
            playlistPutData.events = [{
                id: ev.id,
                description: state.description,
                tags: state.tags,
            }]
        }
        
        if (state.hasActivePayment) {
            const ppvInPayment = ppvSelected && !!state.price
            const subscriptionInPayment = subscriptionSelected && !!state.subscription
            const paymentProps = {
                price_plans: ppvInPayment ? [state.price] : [],
                subscriptions: subscriptionInPayment ? [{id: state.subscription.id}] : [],
            }
            if (!subscriptionInPayment && ppvInPayment) {
                const startDate = new Date(startTime)
                const startPlusPaymentPeriod = startDate.setDate(startDate.getDate() + state.payment_period)
                paymentProps.payment_required_until = new Date(startPlusPaymentPeriod).toISOString()
            } else {
                paymentProps.payment_required_until = null
            }
            playlistPutData.payment = paymentProps
        } else {
            playlistPutData.payment = {
                price_plans: [],
                subscriptions: [],
            }
        }

        const assetPutData = {}
        if (allowAssetEdit) {
            if (state.game?.id !== asset.game?.id)
                assetPutData.game_id = state.game ? state.game.id : null
            if (state.open_for_playlists !== asset.open_for_playlists)
                assetPutData.open_for_playlists = state.open_for_playlists
            if (state.start_timestamp.toISOString() !== new Date(asset.start_timestamp).toISOString())
                assetPutData.timestamp = state.start_timestamp.toISOString()
        }
        
        const query = {access_token: token};
        const putPlaylistDataAsString = JSON.stringify(playlistPutData)
        const putAssetDataAsString = JSON.stringify(assetPutData)
        const anyEdits = putPlaylistDataAsString !== "{}" || putAssetDataAsString !== "{}"

        console.log(JSON.stringify(putPlaylistDataAsString, undefined, 2));

        // Perform the main PUT request
        if (putPlaylistDataAsString !== "{}") {
            const {error} = await Backend.put("/playlist/" + playlist.id, putPlaylistDataAsString, query)
            if (error) {
                console.error("Failed to PUT playlist", error)
                showFeedback("warning", "Failed to edit metadata, " +  error)
                return
            }
        }

        // Perform the asset PUT request
        if (putAssetDataAsString !== "{}") {
            console.log("Saving asset data:", JSON.stringify(putAssetDataAsString))
            let body = new FormData()
            body.append("params", putAssetDataAsString)
            const {error} = await Backend.put("/asset/" + asset.id, body, query, {json: false})
            if (error) {
                console.error("Failed to PUT asset", error)
                showFeedback("warning", "Failed to edit metadata, " +  error)
                return
            }
        }

        // update the compilation title in header if the metadata the user is editing is an active compilation
        if (playlist.id === compilationId) {
            if (state.description !== playlist.description) {
                setCompilationInfo({
                    id: compilationId,
                    title: state.description,
                });
            }
        }
        
        if (anyEdits) {
            showFeedback("success", "Metadata edited successfully!");
            mutateByRegex(/^\/(playlist)|(event)|(asset)|(live_ingest)|(game\/\d+\/events)/)
        } else {
            showFeedback("success", "No changes");
        }
        onClose();
    }
    
    return (
        <CustomModal isOpen shouldCloseOnOverlayClick={false} onRequestClose={onClose} className="medium edit-metadata-modal">
            <div className="scrollable">
                <form onSubmit={(e) => e.preventDefault()} className="edit-metadata-cont">
                    <div className="edit-metadata-title">Edit Video Metadata</div>
                    <div className="input-container" ref={descriptionRef}>
                        <label className="input-title">
                            Title
                        </label>
                        <textarea id="metadata-field-description"
                                  rows="3"
                                  onChange={(e) => dispatch({type: "description", payload: e.target.value})}
                                  value={state.description}
                                  className="metadata-active-input" />
                    </div>
                    {showErrorMessage("Min 5 characters", invalidDescription)}
                    <div className="input-container">
                        <label className="input-title">Published</label>
                        <>
                            <div
                                onClick={() => dispatch({type: "is_private", payload: true})}
                                className={classNames("option-checkbox", {"active": state.is_private})}>
                                {state.is_private?
                                    <FiCheckSquare className="check-box-icon"/> :
                                    <FiSquare className="check-box-icon"/>}
                                <div className="option-box-title">
                                    Not yet published
                                    <div className="option-extra-message">
                                        <BsInfoCircle className="info-icon"/> video will be unlisted (can be changed later)
                                    </div>
                                </div>
                            </div>
                            <div
                                onClick={() => dispatch({type: "is_private", payload: false})}
                                className={classNames("option-checkbox", {"active": !state.is_private})}>
                                {!state.is_private?
                                    <FiCheckSquare className="check-box-icon"/> :
                                    <FiSquare className="check-box-icon"/>}
                                <div className="option-box-title">
                                    Published
                                </div>
                            </div>
                        </>
                    </div>
                    {(isSef || hasChannels) && (
                        <div className="input-container">
                            <label className="input-title">Channels</label>
                            <UploadChannelSection
                                selectedChannel={state.channels}
                                setSelectedChannel={(c) => dispatch({type: "channels", payload: c})}
                            />
                        </div>
                    )}
                    {/* TODO Temporary fix for stream without category */}
                    {(allowTagEdit && Array.isArray(state.tags)) && (
                        <div className="input-container">
                            <label className="input-title">Category</label>
                            <UploadCategorySection
                                tags={state.tags}
                                setTags={(t) => dispatch({type: "tags", payload: t})}
                            />
                        </div>
                    )}
                    {showErrorMessage("Please add minimum 1 category", (isSef && state.tags.length <= 0))}
                    <div className="input-container">
                        <label className="input-title">Thumbnail</label>
                        <ThumbnailSelector
                            selected={state.thumbnail_url}
                            onChange={setThumbnail}
                            playlistClips={playlist}
                        />
                    </div>
                    {allowAssetEdit && (
                        <div className="input-container match-associate">
                            <label className="input-title">Associate with match</label>
                            <UploadMatchAssociate
                                selectedGame={state.game}
                                setSelectedGame={(g) => dispatch({type: "game", payload: g})}
                            />
                        </div>
                    )}
                    {(isSef && allowAddingPayment) && (
                        <div className="input-container">
                            <label className="input-title">Payment</label>
                            <PaymentInputField
                                ppvSelected={ppvSelected}
                                subscriptionSelected={subscriptionSelected}
                                merchant={state.merchant} 
                                price={state.price}
                                subscription={state.subscription}
                                paymentPeriod={state.payment_period}
                                paymentActive={state.hasActivePayment}
                                setPpvSelected={setPpvSelected}
                                setSubscriptionSelected={setSubscriptionSelected}
                                setMerchant={(m) => dispatch({type: "merchant", payload: m})} 
                                setPrice={(p) => dispatch({type: "price", payload: p})}
                                setSubscription={(s) => dispatch({type: "subscription", payload: s})}
                                setPaymentPeriod={(v) => handlePaymentPeriod(v)}
                                setPaymentActive={(v) => handlePaymentActivation(v)}
                                startTime={startTime}
                                editPayment/>
                            {hasInputError && showErrorMessage("Please complete payment details or disable payment", !paymentDetailsComplete)}
                        </div>
                    )}
                    <div className="input-container">
                        <label className="input-title">Pre-roll</label>
                        <UploadPrePostRollSection
                            selected={state.prerolls}
                            setSelected={(p) => dispatch({type: "prerolls", payload: p})}
                        />
                    </div>
                    <div className="input-container">
                        <label className="input-title">Post-roll</label>
                        <UploadPrePostRollSection
                            selected={state.postrolls}
                            setSelected={(p) => dispatch({type: "postrolls", payload: p})}
                            postRoll
                        />
                    </div>
                    {allowAssetEdit && (
                        <div className="input-container">
                            <label className="input-title">Time of recording</label>
                            <UploadTimeOfRecordingSection timestamp={state.start_timestamp} onChange={(date) => updateDate(date)}/>
                        </div>
                    )}
                    {allowAssetEdit && (
                        <div className="input-container">
                            <label className="input-title">Allow others to use this video in compilations</label>
                            <>
                                <div
                                    onClick={() => dispatch({type: "open_for_playlists", payload: true})}
                                    className={classNames("option-checkbox", {"active": state.open_for_playlists})}>
                                    {state.open_for_playlists?
                                        <FiCheckSquare className="check-box-icon"/> :
                                        <FiSquare className="check-box-icon"/>}
                                    <div className="option-box-title">
                                        Yes
                                        <div className="option-extra-message">
                                            <BsInfoCircle className="info-icon"/> video can be used for making compilation
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => dispatch({type: "open_for_playlists", payload: false})}
                                    className={classNames("option-checkbox", {"active": !state.open_for_playlists})}>
                                    {!state.open_for_playlists?
                                        <FiCheckSquare className="check-box-icon"/> :
                                        <FiSquare className="check-box-icon"/>}
                                    <div className="option-box-title">
                                        No
                                    </div>
                                </div>
                            </>
                        </div>
                    )}
                    {/* <div className="input-container">
                        <label className="input-title">Featured</label>
                        <div className="checkbox-options">
                            <div onClick={() => dispatch({type: "featured", payload: true})}>
                                {state.featured? <BsCheckSquare/> : <BsSquare />}
                                Yes
                            </div>
                            <div onClick={() => dispatch({type: "featured", payload: false})}>
                                {!state.featured? <BsCheckSquare/> : <BsSquare />}
                                No
                            </div>
                        </div>
                    </div> */}
                </form>
            </div>
            <div className="confirm-cancel-btn-cont">
                <button type="submit" disabled={hasInputError} onClick={onClickSubmit} className="green-btn">
                    Submit
                </button>
                <button type="reset" onClick={onClickClose}>
                    Cancel
                </button>
            </div>
        </CustomModal>
    )
}