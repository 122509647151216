import React from "react"
import { useFeedbackMessage } from "../stores/FeedbackMessage";
// import { FacebookShareButton, FacebookIcon } from "react-share";
// import { TwitterShareButton, TwitterIcon } from "react-share";
// import { EmailShareButton, EmailIcon } from "react-share";
import { FiLink } from "react-icons/fi";
import "../pages/Library/EditPlaylist/MetadataSection.css";

function ShareVideo ({playlist, url}) {

    const {showFeedback} = useFeedbackMessage();

    const copyVideoLink = () => {
        navigator.clipboard.writeText(url);
        showFeedback("success", "Video link is copied to clipboard");
    }

    return (
        <div className="share-video-cont">
            {/* <FacebookShareButton  className="share-social-media-btn" url={url}>
                <FacebookIcon size={25} round={true}/>
            </FacebookShareButton>
            <TwitterShareButton className="share-social-media-btn" url={url}>
                <TwitterIcon size={25} round={true}/>
            </TwitterShareButton> */}
            {/*NOTE: Email share breaks if it immediately gets removed from DOM, careful with hide on click*/}
            {/* <EmailShareButton className="share-social-media-btn" url={url} subject={`Clip: ${playlist.description}`}>
                <EmailIcon size={25} round={true}/>
            </EmailShareButton> */}
            <div onClick={copyVideoLink} className="share-social-media-btn">
                <div className="copy-link-icon"><FiLink/></div>
            </div>
        </div>
    )
}

export default ShareVideo