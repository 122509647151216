import { useBackend } from "./Backend"
import Config from "./Config"

// This in a separate function in case this will be used in other places
function useGetClubConfig () {
    const isSef = Config.association === "SEF"
    const {data} = useBackend(isSef ? "/fotbollplay/clubconfig" : null)
    if (!data) return null
    let allConfigs = Object.values(data).flat() || null
    return allConfigs
}

export function useGetRouteFromChannel (channel=null) {
    const clubConfigs = useGetClubConfig()
    if (!clubConfigs || !channel) return null
    const clubConfig = clubConfigs.find(config => config.channels.some(c => c.id === channel))
    const route = clubConfig?.route || null
    return route
}