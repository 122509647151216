import React, { useState, useRef, useEffect } from "react"
import { useBackend } from "../../utility/Backend";
import classNames from "classnames"
import { ClickOutside } from "../../utility/Utilities";
import "./Payment.css"
import {TiArrowSortedDown} from "react-icons/ti";
import {FiCheckSquare, FiSquare} from "react-icons/fi";
import PayPerView from "./PayPerView";
import Subscription from "./Subscription";

export default function PaymentInputField ({
    ppvSelected,
    subscriptionSelected,
    setPpvSelected,
    setSubscriptionSelected,
    merchant, 
    price, 
    subscription,
    paymentPeriod,
    paymentActive, 
    setMerchant, 
    setPrice, 
    setSubscription,
    setPaymentPeriod,
    setPaymentActive, 
    startTime,
    editPayment=false,
}) {

    const [showMerchants, setShowMerchants] = useState(false)
    const dropdownListRef = useRef(null)
    ClickOutside(dropdownListRef, setShowMerchants)

    useEffect(() => {
        if (paymentActive && !merchant && merchants.length === 1) {
            handleSelectMerchant(merchants[0])
        }
    }, [paymentActive])

    const {data: merchantsData} = useBackend("/cee_merchant", {});
    const merchants = merchantsData?.cee_merchants || []

    const handleSelectMerchant = (merchant) => {
        setMerchant(merchant)
        setShowMerchants(false)
        setPrice(null)
        setSubscription(null)
        if (!subscriptionSelected) setPaymentPeriod(3)   
    }

    const activatePayment = () => {
        setPaymentActive(true)
    }

    const deactivatePayment = () => {
        setPaymentActive(false)
    }

    const handleShowMerchants = () => {
        setShowMerchants(!showMerchants)
    }

    const merchantOptions = (
        <div ref={dropdownListRef} className="input-container payment-dropdown">
            <label className="input-title">Merchant</label>
            <div onClick={handleShowMerchants} className="upload-dropdown-cont">
                <div className="upload-dropdown-title">
                    {merchant? merchant.name : "Select merchant"}
                    <TiArrowSortedDown/>
                </div>
                {showMerchants && (
                    <div className="upload-dropdown-list">
                        <ul className="payment-option-list">
                            {merchants.length === 0? (
                                <li onClick={() => setShowMerchants(false)} className="payment-option-single no-merchant">
                                    No available merchant
                                </li>
                            ) : (
                                merchants.map((m) => {
                                    return (
                                        <li
                                            key={m.id}
                                            onClick={() => handleSelectMerchant(m)}
                                            className={classNames("payment-option-single", {"active": m.name === merchant?.name})}
                                            >
                                            {m.name}
                                        </li>
                                    )
                                })
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )

    return (
        <div className="payment-cont">
            <div
                onClick={deactivatePayment}
                className={classNames("option-checkbox", {"active": !paymentActive})}
                >
                {!paymentActive?
                    <FiCheckSquare className="check-box-icon"/> :
                    <FiSquare className="check-box-icon"/>}
                <div className="option-box-title">
                    Disabled
                </div>
            </div>
            <div
                onClick={activatePayment}
                className={classNames("option-checkbox", {"active": paymentActive})}
                >
                <div className="enabled-payment">
                    <div className="enabled-payment-title">
                        {paymentActive?
                            <FiCheckSquare className="check-box-icon"/> :
                            <FiSquare className="check-box-icon"/>}
                        <div className="">
                            Enabled
                        </div>
                    </div>
                    {paymentActive && (
                        <div className="payment-options-cont">
                            {merchantOptions}
                            <Subscription
                                merchant={merchant}
                                subscription={subscription}
                                setSubscription={setSubscription}
                                subscriptionSelected={subscriptionSelected}
                                setSubscriptionSelected={setSubscriptionSelected}
                                />
                            <PayPerView
                                ppvSelected={ppvSelected}
                                setPpvSelected={setPpvSelected}
                                paymentPeriod={paymentPeriod}
                                setPaymentPeriod={setPaymentPeriod} 
                                price={price}
                                setPrice={setPrice}
                                subscriptionSelected={subscriptionSelected}
                                merchant={merchant}
                                startTime={startTime}
                                editPayment={editPayment}
                                />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}