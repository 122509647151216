import React, {useState, useEffect} from "react";
import {useLoginSession} from "../../../stores/loginSession";
import { getDateFormat, useUpdateSearchParams } from "../../../utility/Utilities";
import { useFeedbackMessage } from "../../../stores/FeedbackMessage";
import { useNavigationBlock } from "../../Layout/CustomRouter";
// import AutoTabInput from "../../../components/AutoTabInput";
import Backend from "../../../utility/Backend";
import "./CreateStream.css";
import {showErrorMessage} from "../../../utility/Utilities";
import UploadMatchAssociate from "../../UploadVideo/UploadMatchAssociate";
import UploadChannelSection from "../../UploadVideo/UploadChannelSection";
import UploadCategorySection from "../../UploadVideo/UploadCategorySection";
import UploadPrePostRollSection from "../../UploadVideo/UploadPrePostRollSection";
import UploadThumbnailSection from "../../UploadVideo/UploadThumbnailSection";
import UploadPublishPeriod from "../../UploadVideo/UploadPublishPeriod";
import StreamDuration from "../StreamComponents/StreamDuration";
import Config from "../../../utility/Config";
import SmallDropdown from "../../../components/SmallDropdown";
import UploadTimeOfRecordingSection from "../../UploadVideo/UploadTimeOfRecordingSection";
import {FiSquare, FiCheckSquare} from "react-icons/fi";
import PaymentInputField from "../../UploadVideo/PaymentInputField";
import { useMutateByRegex } from "../../../utility/Utilities";

function CreateStream ({ cancelScheduleStream }) {

    const {token, allowAddingPayment} = useLoginSession();
    const {showFeedback} = useFeedbackMessage();
    const [,updateSearchParams,] = useUpdateSearchParams();
    const mutateByRegex = useMutateByRegex();
    const refreshStreams = () => mutateByRegex(/^\/|(live_ingest)/)

    const isSef = Config.association === "SEF"

    const [newStreamType, setNewStreamType] = useState(Config.streamDefaults.streamType)
    const [newStreamProfile, setNewStreamProfile] = useState(Config.streamDefaults.profile);
    const [newStreamDescription, setNewStreamDescription] = useState("");
    const [selectedChannel, setSelectedChannel] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null);
    const [isFullGame, setIsFullGame] = useState(false)
    const [ppvSelected, setPpvSelected] = useState(false)
    const [subscriptionSelected, setSubscriptionSelected] = useState(false)
    const [merchant, setMerchant] = useState(null)
    const [price, setPrice] = useState(null)
    const [subscription, setSubscription] = useState(null)
    const [paymentPeriod, setPaymentPeriod] = useState(null)
    const [paymentActive, setPaymentActive] = useState(false)
    const [selectedPreRoll, setSelectedPreRoll] = useState([]);
    const [selectedPostRoll, setSelectedPostRoll] = useState([]);
    const [newStreamBroadcastStart, setNewStreamBroadcastStart] = useState(new Date())
    const [newStreamDuration, setNewStreamDuration] = useState(3);
    const [imageUrl, setImageUrl] = useState(null)
    const [imageFile, setImageFile] = useState(null)
    const [uploadThumbnail, setUploadThumbnail] = useState(false)
    const [customThumbnail, setCustomThumbnail] = useState(false)
    const [publishPeriod, setPublishPeriod] = useState(Config.streamDefaults.publishPeriod)
    const [hasInputError, setHasInputError] = useState(false)

    // TODO better error check
    const ppvComplete = subscriptionSelected ? (ppvSelected && !!price) : (ppvSelected && !!price && !!paymentPeriod)
    const subscriptionComplete = subscriptionSelected && !!subscription
    const paymentMethodSelected = ppvComplete || subscriptionComplete
    const paymentDetailsComplete = paymentActive ? (!!merchant && paymentMethodSelected) : true

    useEffect(() => {
        if (!hasInputError) return

        if (hasInputError) {

            const fieldsCompleted = newStreamDescription.length >= 5 &&
                newStreamDuration < 9 &&
                newStreamDuration > 0 &&
                selectedChannel.length > 0 &&
                tags.length > 0

            if (!paymentActive) {
                if (fieldsCompleted) setHasInputError(false)
            }

            if (paymentActive) {
                if (fieldsCompleted && paymentDetailsComplete) setHasInputError(false)
            }
        }
    }, [newStreamDescription, newStreamDuration, selectedChannel, tags, paymentActive, merchant, price, paymentDetailsComplete])

    useEffect(() => {
        if (selectedGame) setIsFullGame(true)
        else setIsFullGame(false)
    }, [selectedGame])

    useEffect(() => {
        if (!!merchant && !subscriptionSelected && !ppvSelected) {
            setPpvSelected(true)
            setSubscriptionSelected(true)
        }
    }, [merchant])

    // Set PPV payment period to null if subscription is enabled
    useEffect(() => {
        if (!subscriptionSelected) setPaymentPeriod(3)
        else setPaymentPeriod(null)
    }, [subscription, subscriptionSelected])

    const pastSchedule = (new Date(newStreamBroadcastStart) - new Date()) < -1800000

    // TODO maybe need to check more inputs?
    const scheduleStreamFormFilled = newStreamDescription.length > 0 || selectedChannel.length > 0 || tags.length > 0
    useNavigationBlock("The schedule stream form has unsaved changes. Are you sure you wish to leave?", scheduleStreamFormFilled)

    const handleSelectThumbnail = (imgAndUrl) => {
        setImageUrl(imgAndUrl.imageUrl)
        setImageFile(imgAndUrl.imageFile)
    }

    const resetCreateStream = () => {
        setNewStreamProfile(Config.streamDefaults.profile);
        setNewStreamDescription("");
        setSelectedChannel([]);
        setTags([]);
        setSelectedGame(null);
        setIsFullGame(false);
        setMerchant(null);
        setPrice(null);
        setSubscription(null)
        setPaymentPeriod(null)
        setPaymentActive(false);
        setSelectedPreRoll([]);
        setSelectedPostRoll([]);
        setNewStreamBroadcastStart(new Date());
        setNewStreamDuration(3);
        setImageUrl(null);
        setImageFile(null);
        setUploadThumbnail(false);
        setCustomThumbnail(false);
        setPublishPeriod(Config.streamDefaults.publishPeriod);
        showFeedback();
        setHasInputError(false);
    };

    const closeScheduleStream = () => {
        resetCreateStream()
        cancelScheduleStream()
    }

    const updateNewStreamValidity = (date) => {
        setNewStreamBroadcastStart(date);
    };

    const broadcastStartMinute = new Date(newStreamBroadcastStart).getMinutes();
    const validFromMinusPreBroadcast = new Date(newStreamBroadcastStart).setMinutes(broadcastStartMinute - 15)
    const newStreamValidFrom = new Date(validFromMinusPreBroadcast).toISOString()

    const broadcastStartHour = new Date(newStreamBroadcastStart).getHours();
    const hourPlusDuration = broadcastStartHour + parseInt(newStreamDuration || 0);
    const datePlusDuration = new Date(newStreamBroadcastStart).setHours(hourPlusDuration);
    const newStreamValidTo = new Date(datePlusDuration).toISOString();

    const selectedChannelGroupsId = selectedChannel.map(channel => channel.usergroups
        .map(group => {return ({id: group.id})}))
    const combinedGroupsId = [].concat.apply([], selectedChannelGroupsId)

    const groupsToPermission = [...new Map(combinedGroupsId.map(id => [id["id"], id])).values()];

    const createStream = async () => {

        if (newStreamDescription.length < 5) {
            setHasInputError(true)
            return
        }
        if (newStreamDuration > 9) {
            setHasInputError(true)
            return
        }
        if (newStreamDuration <= 0) {
            setHasInputError(true)
            return
        }
        if (isSef && selectedChannel.length <= 0) {
            setHasInputError(true)
            return
        }
        if (isSef && tags.length <= 0) {
            setHasInputError(true)
            return
        }
        if (!paymentDetailsComplete) {
            setHasInputError(true)
            return
        }

        let uploadedThumbnail = undefined

        if (customThumbnail) {
            let body = new FormData();
            body.append("image", imageFile);

            const {data: uploadedImageData, error: uploadImageError} = await Backend.post(
                "/userimage",
                body,
                {type: "thumbnail", access_token: token},
                {json:false}
            )

            if (uploadImageError) {
                showFeedback("warning", "Failed to upload image: " + uploadImageError);
            }

            uploadedThumbnail = uploadedImageData.path
        }

        const query = {access_token: token};

        const channelsId = selectedChannel.map((channel) => {
            return ({id: channel.id})
        })

        const gameId = selectedGame? selectedGame.id : undefined

        let isPrivate = true
        if (publishPeriod === 1) isPrivate = false

        const streamValidDate = new Date(newStreamBroadcastStart)
        const validityPlusPaymentPeriod = streamValidDate.setDate(streamValidDate.getDate() + paymentPeriod)

        const newStreamDetails = {
            "broadcast_start": newStreamBroadcastStart.toISOString(),
            "playlist": {
                "description": newStreamDescription,
                "is_private": isPrivate,
                "thumbnail_url": uploadedThumbnail,
                "tags": tags,
                "channels": channelsId,
                "prerolls": selectedPreRoll.map(p => ({id: p.id})),
                "postrolls": selectedPostRoll.map(p => ({id: p.id})),
                "usergroups_view": groupsToPermission,
                "usergroups_edit": groupsToPermission,
                "usergroups_grant": groupsToPermission,
            },
            "videoasset": {
                "game_id": gameId,
                "content_source": "",
                "tags": tags,
                "open_for_playlists": false,
                "channels": channelsId,
                "usergroups_playlist_create": groupsToPermission,
                "usergroups_edit": groupsToPermission,
                "usergroups_grant": groupsToPermission,
            }
        }

        if (paymentActive) {
            const ppvInPayment = ppvSelected && !!price
            const subscriptionInPayment = subscriptionSelected && !!subscription
            const paymentProps = {
                price_plans: ppvInPayment ? [price] : [],
                subscriptions: subscriptionInPayment ? [{id: subscription.id}] : [],
            }
            if (!subscriptionInPayment && ppvInPayment) {
                paymentProps.payment_required_until = new Date(validityPlusPaymentPeriod).toISOString()
            }
            newStreamDetails.playlist.payment = paymentProps
        }

        if (newStreamType === "RTMP") newStreamDetails.rtmp_stream_key = {
            "profile": newStreamProfile,
            "valid_from": newStreamValidFrom,
            "valid_to": newStreamValidTo,
        }

        if (newStreamType === "SRT") newStreamDetails.srt_stream_key = {
            "profile": newStreamProfile,
            "valid_from": newStreamValidFrom,
            "valid_to": newStreamValidTo,
        }

        if (isFullGame) newStreamDetails.is_full_game = true

        console.log(newStreamDetails);

        const {data, error: createStreamError} = await Backend.post("/live_ingest", JSON.stringify(newStreamDetails), query)
        
        if (createStreamError) {
            console.error("Failed to POST", createStreamError);
            showFeedback("warning", "Failed to schedule stream, " + createStreamError);
        } else {
            console.log("New stream created");
            refreshStreams()
            resetCreateStream();
            updateSearchParams({"stream": data.id})
            showFeedback("success", "Stream scheduled successfully!");
        }
    }

    const createStreamForm = (
        <form className="form-dual-page">
            <div className="input-container stream-profile-input">
                <label htmlFor="" className="input-title">Stream type</label>
                <div className="stream-profile-cont">
                    <SmallDropdown 
                        value={newStreamType}
                        options={isSef? ["RTMP"] : ["RTMP", "SRT"]}
                        onChange={(v) => setNewStreamType(v)}/>
                </div>
            </div>
            <div className="input-container stream-profile-input">
                <label htmlFor="" className="input-title">Output quality profile</label>
                <div className="stream-profile-cont">
                    <SmallDropdown 
                        value={Config.streamProfileMapping[newStreamProfile]}
                        options={Object.values(Config.streamProfileMapping)}
                        onChange={(v) => setNewStreamProfile(Config.getStreamProfileKey(v))}/>
                </div>
                <div className="stream-time-info">
                    For the best experience, try to roughly match the input quality. I.e., if you're producing 1920x1080 content then you should pick a 1080p profile.
                </div>
            </div>
            <div className="input-container">
                <label className="input-title">Title</label>
                <input
                    type="text"
                    onChange={(e) => setNewStreamDescription(e.target.value)}
                    value={newStreamDescription}/>
                {hasInputError && showErrorMessage("Title min 5 characters", newStreamDescription.length < 5)}
            </div>
            <div className="input-container">
                <label className="input-title">Channels</label>
                <UploadChannelSection autoFill
                                      selectedChannel={selectedChannel}
                                      setSelectedChannel={setSelectedChannel} />
                {hasInputError && showErrorMessage("Please add minimum 1 channel", (isSef && selectedChannel.length <= 0))}
            </div>
            <div className="input-container">
                <label className="input-title">Category</label>
                <UploadCategorySection tags={tags} setTags={setTags}/>
                {hasInputError && showErrorMessage("Please add minimum 1 category", (isSef && tags.length <= 0))}
            </div>
            {/* TODO figure out best position for game */}
            <div className="input-container">
                <label className="input-title">Associate with match</label>
                <UploadMatchAssociate selectedGame={selectedGame} setSelectedGame={setSelectedGame}/>
                {selectedGame && (
                    <div onClick={() => setIsFullGame(!isFullGame)} className="full-game-option">
                        {isFullGame ? <FiCheckSquare/> : <FiSquare/>}
                        Full game
                    </div>
                )}
            </div>
            {(isSef && allowAddingPayment) && (
                <div className="input-container">
                    <label className="input-title">Payment</label>
                    <PaymentInputField
                        ppvSelected={ppvSelected}
                        subscriptionSelected={subscriptionSelected}
                        merchant={merchant} 
                        price={price}
                        subscription={subscription}
                        paymentPeriod={paymentPeriod}
                        paymentActive={paymentActive}
                        setPpvSelected={setPpvSelected}
                        setSubscriptionSelected={setSubscriptionSelected}
                        setMerchant={setMerchant} 
                        setPrice={setPrice}
                        setSubscription={setSubscription}
                        setPaymentPeriod={setPaymentPeriod}
                        setPaymentActive={setPaymentActive}
                        startTime={newStreamBroadcastStart}
                        />
                    {hasInputError && showErrorMessage("Please complete payment details or disable payment", !paymentDetailsComplete)}
                </div>
            )}
            <div className="input-container">
                <label className="input-title">Pre-roll</label>
                <UploadPrePostRollSection selected={selectedPreRoll} setSelected={setSelectedPreRoll}/>
                <div className="stream-time-info">
                    Included only for Video On Demand, after livestream is over
                </div>
            </div>
            <div className="input-container">
                <label className="input-title">Post-roll</label>
                <UploadPrePostRollSection selected={selectedPostRoll} setSelected={setSelectedPostRoll} postRoll/>
                <div className="stream-time-info">
                    Included only for Video On Demand, after livestream is over
                </div>
            </div>
            <div className="input-container">
                <label className="input-title">Broadcast start, approximately when users should tune in</label>
                <UploadTimeOfRecordingSection timestamp={newStreamBroadcastStart} onChange={updateNewStreamValidity}/>
                {/* TODO better message */}
                <div className="stream-time-info">
                    Users will be able to tune in as soon as we receive input. Make sure any pre-stream testing is done on a separate, unpublished, stream.
                    <br />
                    Ingest will be possible from {getDateFormat(newStreamValidFrom, true, true)}
                </div>
                {showErrorMessage("Cannot schedule stream in the past", pastSchedule)}
            </div>
            <div className="input-container">
                <label htmlFor="" className="input-title">Maximum duration to reserve</label>
                <StreamDuration 
                    streamStartTime={newStreamBroadcastStart} 
                    duration={newStreamDuration} 
                    onChange={setNewStreamDuration}
                    hasInputError={hasInputError}
                    setHasInputError={setHasInputError}/>
            </div>
            <div className="input-container">
                <label className="input-title">Thumbnail</label>
                <UploadThumbnailSection 
                    imageUrl={imageUrl} 
                    selectThumbnail={handleSelectThumbnail}
                    uploadThumbnail={uploadThumbnail} 
                    setUploadThumbnail={setUploadThumbnail}
                    customThumbnail={customThumbnail}
                    setCustomThumbnail={setCustomThumbnail}/>
            </div>
            <div className="input-container">
                <label className="input-title">Published</label>
                <UploadPublishPeriod publishPeriod={publishPeriod} setPublishPeriod={setPublishPeriod}/>
            </div>
            <div className="complete-all-fields-msg">{hasInputError && showErrorMessage("Please complete all required field(s) above to upload", hasInputError)}</div>
        </form>
    );

    return (
        <div className="create-stream-cont">
            {createStreamForm}
            <div className="confirm-cancel-btn-cont between">
                <button type="button" disabled={hasInputError || pastSchedule} onClick={createStream} className="green-btn">Create</button>
                <button type="button" onClick={closeScheduleStream}>Cancel</button>
            </div>
        </div>
    )
}

export default CreateStream;