import "./FieldVisionDebugInfo.css";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useVideoPlayer} from "../VideoPlayerProvider";
import Config from "../../../utility/Config";
import classNames from "classnames";
import {FaStepBackward, FaStepForward} from "react-icons/fa";
import VideoOverlay from "./VideoOverlay";
import {getInterpolatedFromMap, secToFrame, useFieldVisionData} from "../../../utility/useFieldVisionData";
import {VisualizePOI} from "./VisualizePOI";
import {deconstructPlaybackUrl} from "../../../utility/Utilities";

const AUDIO_RMS_PEAK = Config.association === "SHL" ? 0.065 : 0.085
const ENTITY_TYPES = Config.association === "SHL" ?
    ["puck", "player", "goaltender", "referee", "centerIce", "faceoff", "goal"] :
    ["ball", "player", "goalkeeper", "referee"]
const RENDERED_ENTITY_CLS = {
    "ball": "poi-entity-ball",
    "puck": "poi-entity-ball",
    "player": "poi-entity-player",
    "referee": "poi-entity-referee",
    "goalkeeper": "poi-entity-keeper",
    "goaltender": "poi-entity-keeper",
}

function AudioBarGraph ({
    frameNumber,
    getCurrentTime,
    assetId,
    assetTimeOffsetMs,
    fps,
    min,
    max,
    highlight,
    sampleRate,
    interval,
}) {
    const canvasRef = useRef(null)
    const map = useFieldVisionData("audio/rms", frameNumber, getCurrentTime, assetId, assetTimeOffsetMs, fps, true, "&track=-1")

    const renderFrame = Math.floor(frameNumber / interval) * interval

    const sampledFrames = useMemo(() => {
        // Calculate total frames to render
        const halfInterval = Math.floor(interval / 2)
        const fullStartFrame = Math.max(renderFrame - halfInterval, 0)
        const fullEndFrame = renderFrame + halfInterval + interval

        const frames = []
        for (let i = fullStartFrame; i <= fullEndFrame; i += sampleRate) {
            frames.push(Math.round(i))
        }
        return frames
    }, [sampleRate, interval, renderFrame, map.has(renderFrame), map.has(renderFrame + interval)])

    useEffect(() => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext("2d")
        const { width, height } = canvas

        // Bar dimensions
        const barWidth = width / sampledFrames.length

        // Full render
        ctx.clearRect(0, 0, width, height)
        sampledFrames.forEach((frame, idx) => {
            const rms = map.has(frame) ? map.get(frame) : 0
            const normalizedHeight = Math.min(1, Math.max(0, (rms - min) / (max - min)))
            const colorIntensity = Math.max(0, Math.min(1, (rms - highlight) / (max - highlight)))
            const gbValue = Math.floor(200 - colorIntensity * 200)
            const barColor = `rgba(255, ${gbValue}, ${gbValue}, 0.5)`

            const x = idx * barWidth
            const barHeight = normalizedHeight * height

            ctx.fillStyle = barColor
            ctx.fillRect(x, height - barHeight, barWidth, barHeight)
        })
    }, [map, min, max, highlight, sampledFrames])

    const frameOffset = (frameNumber - renderFrame) / sampleRate
    const frameOffsetPercent = sampledFrames.length === 0 ? 0 : frameOffset * 100 / sampledFrames.length
    const audioRms = getInterpolatedFromMap(map, frameNumber, 0)

    return (
        <div className="audio-bar-graph-container">
            <div
                className="audio-bar-viewport"
                style={{
                    transform: `translateX(-${frameOffsetPercent}%)`
                }}
            >
                <canvas ref={canvasRef} className="audio-bar-canvas" />
            </div>
            <div className="current-frame-indicator" />
            <div className="current-audio-rms">
                Audio
                <span className={classNames({"animated-attention-text": audioRms > AUDIO_RMS_PEAK})}>
                    {audioRms.toFixed(4)}
                </span>
            </div>
        </div>
    )
}

function VisualizeEntity ({
    x1,
    y1,
    x2,
    y2,
    type,
    probability,
    trackId,
}) {
    const cls = RENDERED_ENTITY_CLS[type] || "poi-entity-other"
    return (
        <div className={`poi-entity ${cls}`}
             data-info={`${type}\n#${trackId}\n${probability}`}
             style={{
                 left: `${x1 * 100}%`,
                 top: `${y1 * 100}%`,
                 width: `${(x2 - x1) * 100}%`,
                 height: `${(y2 - y1) * 100}%`,
             }}
        />
    )
}

function CroppingPreview ({
    frameNumber,
    getCurrentTime,
    assetId,
    assetTimeOffsetMs, // How far into the videoasset our video starts
    aspectRatio,
    fps=Config.expectedFrameRate,
}) {
    const poi = useFieldVisionData("poi", frameNumber, getCurrentTime, assetId, assetTimeOffsetMs, fps)
    return (
        <VisualizePOI
            x={poi.x}
            y={poi.y}
            rectangle={true}
            cross={true}
            dimming={true}
            aspectRatio={aspectRatio}
        />
    )
}

function EntityBoundingBoxes ({
    frameNumber,
    showEntities,
    getCurrentTime,
    assetId,
    assetTimeOffsetMs, // How far into the videoasset our video starts
    fps,
}) {
    const entities = useFieldVisionData("videoentities", frameNumber, getCurrentTime, assetId, assetTimeOffsetMs, fps)
    const entitiesToShow = entities.filter(({type}) => (
        showEntities.includes(ENTITY_TYPES.includes(type) ? type : "other")
    ))
    return entitiesToShow.map((e, idx) => (
        <VisualizeEntity key={`${idx}_${e.type}`}
                         type={e.type}
                         probability={e.probability}
                         trackId={e.trackId}
                         x1={e.x1}
                         y1={e.y1}
                         x2={e.x2}
                         y2={e.y2}
        />
    ))

}

function VideoFVDebugOverlay ({
    assetId,
    assetTimeOffsetMs, // How far into the videoasset our video starts
    aspectRatio,
    showEntities,
    fps=Config.expectedFrameRate,
}) {
    const {getCurrentTime} = useVideoPlayer()

    const frameNumber = secToFrame(getCurrentTime() + (assetTimeOffsetMs / 1000), fps)
    const cameraZoom = useFieldVisionData("scenes", frameNumber, getCurrentTime, assetId, assetTimeOffsetMs, fps)
    const transition = useFieldVisionData("transitions", frameNumber, getCurrentTime, assetId, assetTimeOffsetMs, fps)
    const whistle = useFieldVisionData("audio/whistle", frameNumber, getCurrentTime, assetId, assetTimeOffsetMs, fps)

    return (
        <>
            {showEntities.length > 0 && (
                <EntityBoundingBoxes
                    frameNumber={frameNumber}
                    showEntities={showEntities}
                    getCurrentTime={getCurrentTime}
                    assetId={assetId}
                    assetTimeOffsetMs={assetTimeOffsetMs}
                    aspectRatio={aspectRatio}
                    fps={fps}
                />
            )}
            {aspectRatio && (
                <CroppingPreview
                    frameNumber={frameNumber}
                    getCurrentTime={getCurrentTime}
                    assetId={assetId}
                    assetTimeOffsetMs={assetTimeOffsetMs}
                    aspectRatio={aspectRatio}
                    fps={fps}
                />
            )}
            <AudioBarGraph frameNumber={frameNumber}
                           getCurrentTime={getCurrentTime}
                           assetId={assetId}
                           assetTimeOffsetMs={assetTimeOffsetMs}
                           fps={fps}
                           min={0}
                           max={AUDIO_RMS_PEAK * 1.3}
                           highlight={AUDIO_RMS_PEAK * 0.3}
                           sampleRate={fps/2}
                           interval={fps * 20 * 2} />
            <div className="poi-tracker-frame-info">
                <div>{assetId} / {frameNumber}</div>
                <div>{cameraZoom || "Camera unset"}</div>
                {transition && (
                    <div className="animated-attention-text">{transition}</div>
                )}
                {whistle && (
                    <div className="animated-attention-text">Whistle detected</div>
                )}
            </div>
        </>
    )
}

function EntityCheckboxes ({ options, active, onChange }) {
    const handleCheckboxChange = (entity) => {
        const updatedEntities = active.includes(entity)
            ? active.filter(item => item !== entity)
            : [...active, entity]
        onChange(updatedEntities)
    }

    const handleAllChange = () => {
        if (active.length === options.length + 1) {
            onChange([]) // Deselect all
        } else {
            onChange([ ...options, "other" ]) // Select all
        }
    }

    return (
        <div className="checkbox-container">
            <label className="checkbox-item">
                <input
                    type="checkbox"
                    checked={active.length === options.length + 1}
                    onChange={handleAllChange}
                />
                <span>All</span>
            </label>
            {options.map((option) => (
                <label key={option} className="checkbox-item">
                    <input
                        type="checkbox"
                        checked={active.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                    />
                    <span>{option}</span>
                </label>
            ))}
            <label className="checkbox-item">
                <input
                    type="checkbox"
                    checked={active.includes("other")}
                    onChange={() => handleCheckboxChange("other")}
                />
                <span>Other</span>
            </label>
        </div>
    )
}

function JumpFramesButton ({ value, onClick, display=null }) {
    display = display || `${Math.abs(value)}f`
    const seconds = value / Config.expectedFrameRate
    return (
        <button className="jump-btn" onClick={() => onClick(seconds)} style={{ position: "relative" }}>
            {value < 0 ? (
                <><FaStepBackward /> <span>{display}</span></>
            ) : (
                <><span>{display}</span> <FaStepForward /></>
            )}
        </button>
    )
}

export default function FieldVisionDebugInfo () {
    const {playbackUrl, playerRef} = useVideoPlayer()
    const [aspectRatio, setAspectRatio] = useState(null)
    const [enabledEntities, setEnabledEntities] = useState(() => [])
    let [assetId, assetTimeOffsetMs] = deconstructPlaybackUrl(playbackUrl)

    if (!assetId) return null // Disabled for compilations, at least for now

    function onSkip (skip) {
        const videoJs = playerRef.current
        if (!videoJs) return
        const currentTime = videoJs.currentTime()
        videoJs.currentTime(Math.max(0, currentTime + skip))
    }

    return (
        <div className="poi-tracker-cont">
            <div>
                <div>
                    Skip frames:
                </div>
                <JumpFramesButton value={-100} onClick={onSkip} />
                <JumpFramesButton value={-25} onClick={onSkip} />
                <JumpFramesButton value={-5} onClick={onSkip} />
                <JumpFramesButton value={-1} onClick={onSkip} />
                <JumpFramesButton value={1} onClick={onSkip} />
                <JumpFramesButton value={5} onClick={onSkip} />
                <JumpFramesButton value={25} onClick={onSkip} />
                <JumpFramesButton value={100} onClick={onSkip} />
            </div>
            <div className="poi-tracker-ar-cont">
                <div>
                    Cropping preview:
                </div>
                <button type="button"
                        disabled={aspectRatio === null}
                        onClick={() => setAspectRatio(null)}>
                    Off
                </button>
                <button type="button"
                        disabled={aspectRatio === "1/1"}
                        onClick={() => setAspectRatio("1/1")}>
                    1:1
                </button>
                <button type="button"
                        disabled={aspectRatio === "9/16"}
                        onClick={() => setAspectRatio("9/16")}>
                    9:16
                </button>
            </div>
            <div>
                <div>
                    Display entities:
                </div>
                <EntityCheckboxes
                    options={ENTITY_TYPES}
                    active={enabledEntities}
                    onChange={setEnabledEntities}
                />
            </div>
            {(aspectRatio || enabledEntities.length > 0) && (
                <VideoOverlay>
                    <VideoFVDebugOverlay
                        assetId={assetId}
                        assetTimeOffsetMs={assetTimeOffsetMs}
                        aspectRatio={aspectRatio}
                        showEntities={enabledEntities}
                    />
                </VideoOverlay>
            )}
        </div>
    )
}
