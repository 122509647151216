import React, {useEffect, useState, useRef} from "react"
import Backend from "../utility/Backend";
import VideoSearchEntry from "./VideoSearchEntry"
import {useSWRConfig} from "swr";
import {useNavigate} from "react-router-dom";
import { Paging } from "../utility/Paging";
import { useAddVideoToAction } from "../utility/Utilities";
import LoadingScreen from "./LoadingScreen";
import VideoAction from "./VideoAction";
import { showErrorMessage } from "../utility/Utilities";
import { forzifyAnalytics } from "../utility/analytics";
import { useBackend } from "../utility/Backend";
import "../components/css/VideoSearchEntry.css"
import { FaSearch } from "react-icons/fa"
import { useLoginSession } from "../stores/loginSession";

const useFetchSearchResultWithTiming = (url, query) => {

    const [fetchDuration, setFetchDuration] = useState(null)

    const startTime = performance.now()

    const {data, error} = useBackend(url, query, {
        revalidateOnFocus: false,
        onSuccess: () => {
            const endTime = performance.now()
            const duration = endTime - startTime
            setFetchDuration(duration.toFixed(0))
        },
    });
    return {data, error, fetchDuration}
};

function SearchResultSingle ({idx, playlist, selectedVideos, addVideoToList, removeVideoFromList}) {

    const {game, date} = playlist

    const {cache} = useSWRConfig();
    const navigate = useNavigate();

    const urlPathname = window.location.pathname
    const urlAfterLibrary = urlPathname.substring(urlPathname.indexOf("y") + 1);

    function onClick () {
        cache.set("editing_playlist", playlist);
        // Doing this a little more manually to avoid using a hook in dozens/hundreds of small components
        const q = new URLSearchParams(window.location.search);
        q.set("editing", "playlist_" + playlist.id);
        navigate("/library" + urlAfterLibrary + "?" + q.toString());
        return false;
    }

    return (
        <VideoSearchEntry
            tabIndex={20+idx} 
            onClick={onClick}
            playlist={playlist}
            game={game}
            timestamp={date}
            active={false}
            disableAction={false}
            selectedVideos={selectedVideos}
            addVideoToList={addVideoToList}
            removeVideoFromList={removeVideoFromList}
            />
    )
}

function FreeSearchResults ({
    searchInput="",
    searchParams=undefined,
    searchUrl=undefined, 
    dateParams=undefined,
    setLoading=undefined,
}) {
    
    const [
        selectedVideos,
        addVideoToList, 
        removeVideoFromList, 
        clearSelectedList,
    ] = useAddVideoToAction()

    const {user} = useLoginSession()

    const [searchResultsPage, setSearchResultsPage] = useState(1)

    const resultsPerPage = 10
    const fromPage = searchResultsPage === 1 ? 0 : (searchResultsPage-1) * resultsPerPage

    const query = {
        count: resultsPerPage,
        from: fromPage,
    }
    
    if (dateParams?.from_date && dateParams?.to_date) {
        query["from_date"] = new Date(dateParams.from_date).toISOString()
        query["to_date"] = new Date(dateParams.to_date).toISOString()
    }

    const {data, error, fetchDuration} = useFetchSearchResultWithTiming("/freetext?" + searchUrl, query)

    const playlist = data?.playlists || []
    const totalPage = Math.ceil(data?.total / resultsPerPage)

    useEffect(() => {
        if (data) setLoading(false)
    }, [playlist])

    useEffect(() => {
        if (error) setLoading(false)
    }, [error])

    const collectObjectParams = () => {
        if (!searchParams) return undefined
        let objectParams = []
        const paramKeys = Object.keys(searchParams)
        if (paramKeys.includes("a")) objectParams.push("action")
        if (paramKeys.includes("p")) objectParams.push("player")
        if (paramKeys.includes("t")) objectParams.push("team")
        const paramsString = objectParams.join(", ")
        return paramsString
    }

    const analyzeSearch = () => {
        forzifyAnalytics("free-search", {
            duration: fetchDuration,
            url: searchUrl,
            total: data.total,
            text: searchInput,
            actions: searchParams.a,
            objects: collectObjectParams(),
            app_name: "mam",
            user: user.id,
        })
    }

    useEffect(() => {
        if (data && fetchDuration) analyzeSearch()
    }, [data])

    const onSearchResultsPageChange = (page) => {
        setSearchResultsPage(page)
    }

    if (!data) return null

    const allVideosInPagePlaylist = playlist.map((e) => {
        const playlist = e.playlist || e
        return playlist
    })

    const selectedVideosId = Array.from(selectedVideos.keys()) 
    const allVideosInPagePlaylistId = allVideosInPagePlaylist.map(v => v.id)
    const allVideoInPageSelected = allVideosInPagePlaylistId.every(v => selectedVideosId.includes(v))

    const onSelectAllVideosInPage = () => {
        if (allVideoInPageSelected) return
        addVideoToList(allVideosInPagePlaylist)
    }
    
    if (error) return (
        <div className="no-results-found">
            Some error occurred. Please try again later
        </div>
    )

    if (playlist.length === 0) return (
        <div className="no-results-found">
            Nothing found
        </div>
    )

    return (
        <div className="search-results-cont free-search">
            <div className="search-results-number">{data.total} Results found</div>
            <VideoAction 
                selectedVideos={selectedVideos}
                clearSelectedList={clearSelectedList}
                showSelectAll={!!data}
                onSelectAllVideosInPage={onSelectAllVideosInPage}
                allVideoInPageSelected={allVideoInPageSelected}
                />
            <ul className="search-results-list">
                {playlist.map((playlist, idx) => {
                    return (
                        <SearchResultSingle 
                            key={playlist.id}
                            idx={idx}
                            playlist={playlist}
                            selectedVideos={selectedVideos}
                            addVideoToList={addVideoToList}
                            removeVideoFromList={removeVideoFromList}/>
                    )
                })}
            </ul>
            <Paging page={searchResultsPage} pageCount={totalPage} onChange={onSearchResultsPageChange}/>
        </div>
    )
}

function FreeSearch () {

    const searchInputRef = useRef(null)

    const [searchInput, setSearchInput] = useState("")
    const [searchUrl, setSearchUrl] = useState(null)
    const [searchParams, setSearchParams] = useState(null)
    const [dateParams, setDateParams] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        searchInputRef.current?.focus()
    }, [])

    const onKeyPress = (e) => {
        // enter key
        if (e.keyCode === 13) {
            if (searchInput.length >= 5) {
                onSubmitSearch()
            }
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyPress);
        return () => {
            window.removeEventListener("keydown", onKeyPress);
        }
    })

    useEffect(() => {
        if (error?.includes("5 characters") && searchInput?.length >= 5) {
            setError(null)
        }
    }, [error, searchInput])

    const onSubmitSearch = async () => {
        if (searchInput.length < 5) {
            setError("Search input must be at least 5 characters")
            return
        }

        setLoading(true)
        setSearchUrl(null)
        setSearchParams(null)
        setDateParams(null)

        await Backend.get(`/freetext/nl?p=${searchInput}`)
            .then(({data, error}) => {
                if (error) {
                    setError(error)
                    setLoading(false)
                    console.log(error)
                }
                if (data) {
                    // Exclude from_date and to_date
                    const filteredParams = Object.fromEntries(
                        Object.entries(data.search_params).filter(([key]) => key !== "from_date" && key !== "to_date")
                    )
                    const dateParams = Object.fromEntries(
                        Object.entries(data.search_params).filter(([key]) => key === "from_date" || key === "to_date")
                    )
                    const queryString = new URLSearchParams(filteredParams).toString()
                    setSearchUrl(queryString)
                    setSearchParams(data.search_params)
                    setDateParams(dateParams)
                    setError(null)
                }
            })
    }

    const loadingScreen = (
        <div className="no-results-found">
            <LoadingScreen/>
        </div>
    )
    
    return (
        <div className="tag-based-search-cont">
            <div className="search-field-cont">
                <input
                    ref={searchInputRef}
                    type="text"
                    placeholder="Min. 5 characters"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="free-search-input"
                    />
                <div onClick={onSubmitSearch} className="search-button">
                    <FaSearch/>
                    Search
                </div>
            </div>
            {showErrorMessage(error, error)}
            {loading && loadingScreen}
            {searchUrl && (
                <FreeSearchResults
                    searchInput={searchInput}
                    searchParams={searchParams}
                    searchUrl={searchUrl}
                    dateParams={dateParams}
                    setLoading={setLoading}
                    />
            )}
        </div>
    )
}

export default FreeSearch