import React, { useState } from "react"
import {useCheckIsAdmin} from "./UserGroups";

export default function AdminDebug ({ children, fsAdmin=true }) {
    const [isFsAdmin, isAdmin] = useCheckIsAdmin()
    const [isVisible, setIsVisible] = useState(true)
    const toggleVisibility = () => setIsVisible(!isVisible)

    if (!isAdmin || (fsAdmin && !isFsAdmin)) return null

    return (
        <div className="admin-debug">
            <div className="admin-debug-toggle" onClick={toggleVisibility}>
                Admin debug {isVisible ? "▼" : "▲"}
            </div>
            {isVisible && <div className="admin-debug-content">{children}</div>}
        </div>
    )
}